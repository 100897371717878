import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import Menubar from '../components/Menubar';
import { Link } from 'react-router-dom';
import '../index.css';
import updateMetaKeywords from '../components/KeywordTag';
import updateMetaDescription from '../components/DescriptTag';
import usePageTracking from '../components/PageTracking';


function AboutUs() {
  const [pageTitle, setPageTitle] =
  useState('Appddiction Studio Software Development, DevSecOps'+
    'Agile Training, Healthcare Staffing');
  useEffect(() => {
    document.title = pageTitle; // Set the page title to the current state value
  }, [pageTitle]);

  // Update meta description dynamically
  useEffect(() => {
    // eslint-disable-next-line max-len
    updateMetaDescription('');
  }, []);

  // Update meta keywords dynamically
  useEffect(() => {
    // eslint-disable-next-line max-len
    updateMetaKeywords('');
  }, []);

  usePageTracking();
  return (
    <>
      <Menubar />
      {/* Hero */}
      <div className='py-lg-20 pt-14 pb-10 bg-cover aboutUsHeroImage
        d-none d-lg-block d-xl-block'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-6 col-lg-7 col-md-8 col-12'>
              <div className='text-white'>
                <h1 className='text-white mb-3 display-4 whiteText-responsive'>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Nav Buttons */}
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-6 col-sm-12 pl-0 pr-0'>
            <Link to='/aboutus'
              className='btn btn-primary-training pt-3'
              data-testid='primary button'>
              <span className='h3'>
                  ABOUT APPDDICTION
              </span>
            </Link>
          </div>
          <div className='col-md-6 col-sm-12 pl-0 pr-0'>
            <Link to='/leadership'
              className='btn btn-secondary-training pt-3'
              data-testid='secondary button'>
              <span className='h3'>
                  LEADERSHIP
              </span>
            </Link>
          </div>
        </div>
      </div>
      {/* Body */}
      <div className='p-5 whiteContainer'>
        <div data-testid='Our History text 1'>
          <div className='pl-5 pr-5 pb-3 contractVpara text-center'>
            <span className='h1 fw-bold aboutUsHeaders row
            justify-content-center aboutUsHeaders'>OUR HISTORY
            </span>
            <span className='row justify-content-center text-left'>
            In 2011, CEO Timothy Porter was deeply moved
            by the tragic loss of a young student&apos;s
            life due to  bullying. Determined to make a change,
            he embarked on a journey to develop an app
            enabling  students to report bullying incidents
            anonymously and promptly. The app&apos;s success in
            empowering students gave rise to Appddiction,
            an organization committed to its mission of
            empowering individuals to create a positive impact.
            </span>
          </div>
        </div>
      </div>
      <div className='lightGreyContainer'>
        <div data-testid='Our Mission text 1'>
          <div className='pl-5 pr-5 pb-3 contractVpara text-center'>
            <span className='h1 fw-bold aboutUsHeaders row
            justify-content-center'>OUR MISSION </span>
            <span className='row justify-content-center text-left'>
            Appddiction Studio is dedicated to fulfilling the evolving
            requirements of our commercial and
            government clients, all while enhancing their mission success
            with top-notch competence,
            efficiency, and cost-effectiveness. We prioritize attention to
            every detail and consider excellent
            customer service as our foundation.
            </span>
          </div>
        </div>
      </div>
      <div className='p-5 whiteContainer'>
        <div data-testid='Here to Help text 1'>
          <div className='pl-5 pr-5 pb-3 contractVpara text-center'>
            <div className='h1 fw-bold aboutUsHeaders row
            justify-content-center'>WE ARE HERE TO HELP</div>
            <div>
            As a training provider, our goal is to foster community and
            enhance your
            company&apos;s capabilities by delivering the Agile training
            that suits your needs.</div>
          </div>
        </div>
        <div className='container-fluid d-flex justify-content-center'
          data-testid='Here toHelp images'>
          <div className='row '>
            <div className="col-lg-3 col-sm-12" id="">
              <div className='productLogo hereToHelpLogos'>
              </div>
              <h5 className='grey-subheader'>
                Accelerate Software Development
              </h5>
            </div>
            <div className="col-lg-3 col-sm-12" id="">
              <div className='performanceLogo hereToHelpLogos'>
              </div>
              <h5 className='grey-subheader'>
                Improve Business/ IT Alignment & Team Morale
              </h5>
            </div>
            <div className="col-lg-3 col-sm-12" id="">
              <div className='adaptationLogo hereToHelpLogos'>
              </div>
              <h5 className='grey-subheader'>
                Adapt to Changing Market Conditions & Priorities
              </h5>
            </div>
            <div className="col-lg-3 col-sm-12" id="">
              <div className='lowPriceLogo hereToHelpLogos'>
              </div>
              <h5 className='grey-subheader'>
                Reduce Project Costs & Waste
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div className='lightGreyContainer'>
        <div data-testid='Our Vision text 1'>
          <div className='pl-5 pr-5 pb-3 contractVpara text-center'>
            <div className='h1 fw-bold aboutUsHeaders row
            justify-content-center'>OUR VISION
            </div>
            <div className='row justify-content-center text-left'>
            At Appddiction Studio, we’re addicted to exceeding expectations,
            enriching lives, and solving problems. We foster partnerships,
            empower our team, and make a difference in our communities.
            Our goal: rave reviews from customers, eager partners, and
            inspired employees.
            </div>
          </div>
        </div>
      </div>
      <div className='p-5 whiteContainer'>
        <div data-testid='Software Development and Agile Training text 1'>
          <div className='pl-5 pr-5 pb-3 contractVpara text-center'>
            <div className='h1 fw-bold row aboutUsHeaders
            justify-content-center'>SOFTWARE DEVELOPMENT AND AGILE TRAINING
            </div>
            <div className='h4 grey-subheader'>Our Subject Matter Experts
            hold highly recognized development and engineering
            certifications.</div>
          </div>
        </div>
        <div className='container-fluid' data-testid='Our Contract
        Portfolio images'>
          <div className='container-fluid justify-content-center'
            data-testid='Here toHelp images'>
            <div className='row d-flex align-ite'>
              <div className="col-lg-4 col-sm-12" id="">
                <div className='row'>
                  <div className='certificateLogo trainingLogos'>
                  </div>
                  <div className='largeGreyNumbers'>
                    50+
                  </div>
                </div>
                <h5 className='grey-subheader'>
                  Private & Public Agile <br></br>Certification Classes Taught
                </h5>
              </div>
              <div className="col-lg-4 col-sm-12 justify-content-center" id="">
                <div className='row'>
                  <div className='coworkersLogo trainingLogos'>
                  </div>
                  <div className='largeGreyNumbers mt-2'>
                    10
                  </div>
                </div>
                <h5 className='grey-subheader'>
                  In-house SAFe®<br></br> Program Consultants
                </h5>
              </div>
              <div className="col-lg-4 col-sm-12 justify-content-center" id="">
                <div className='row'>
                  <div className='trainLogo trainingLogos'>
                  </div>
                  <div className='largeGreyNumbers'>
                    5
                  </div>
                </div>
                <h5 className='grey-subheader'>
                  Agile Release Train (ART)<br></br> Launches of 7-19 teams
                  <br></br>(8-15 per team)
                </h5>
              </div>
            </div>
          </div>
          <div className='container-fluid justify-content-center'
            data-testid='Here toHelp images'>
            <div className='row justify-content-center'>
              <div className="col-lg-4 col-sm-12 justify-content-center" id="">
                <div className='row justify-content-center'>
                  <div className='chefLogo trainingLogos'>
                  </div>
                  <div className='largeGreyNumbers'>
                    3
                  </div>
                </div>
                <h5 className='grey-subheader'>
                  Certified CHEF Trainers
                </h5>
              </div>
              <div className="col-lg-4 col-sm-12 justify-content-center" id="">
                <div className='row justify-content-center'>
                  <div className='ringsLogo trainingLogos'>
                  </div>
                  <div className='largeGreyNumbers'>
                    2
                  </div>
                </div>
                <h5 className='grey-subheader'>
                  Certified Pivotal Cloud<br></br> Foundry Trainers
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default AboutUs;
