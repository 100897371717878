/* eslint-disable max-len */
import Menubar from '../components/Menubar';
import CPSteps from '../components/CPSteps';
import Footer from '../components/Footer';
import { useHistory } from 'react-router-dom';

function CPInformationPage() {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <>
      <Menubar />
      <div className='ml-5 pl-5 mt-5'>
        <button onClick={handleGoBack}
          className='btn btn-cpay'>&lt;&lt;&nbsp;GO BACK</button>
      </div>
      <div className='container my-5'>
        <div className='row'>
          <div className='col-4 mt-3'>
            <img src='../assets/CareerPayLogo.png'/>
          </div>
          <div className='col-8'>
            <div className='step-description'>Appddiction Studio has partnered with CareerPay
                to make your educational needs come to fruition now!
            </div>
          </div>
        </div>
      </div>
      <CPSteps />
      <Footer />
    </>
  );
}

export default CPInformationPage;
