import React from 'react';

function Help() {
  return (
    <div className='card bg-white mb-3 helpCard'>
      <div className='card-body'>
        <div className='col text-center'>
          <img src='./assets/lifesaver.svg' className='helpIcon pb-3' />
          <div className='card-title h3'>We&apos;re here to help!</div>
        </div>
        <p className='card-text'>
          Use the chat icon on the right to chat with us live or send an email
          to the&nbsp;
          <a href='mailto:tporter@appddictionstudio.com'>
            Appddiction Help Team
          </a>
          .
        </p>
      </div>
    </div>
  );
}
export default Help;
