import { combineReducers } from 'redux';
const UPDATE_CART_DISCOUNT = 'UPDATE_CART_DISCOUNT';
const UPDATE_CART_APPLIED_DISCOUNT = 'UPDATE_CART_APPLIED_DISCOUNT';
const UPDATE_CART_SUBTOTAL = 'UPDATE_CART_SUBTOTAL';
const UPDATE_CART_TOTAL ='UPDATE_CART_TOTAL';
const ADD_STUDENT = 'ADD_STUDENT';
const UPDATE_BILLING_FIRSTNAME = 'UPDATE_BILLING_FIRSTNAME';
const UPDATE_BILLING_LASTNAME = 'UPDATE_BILLING_LASTNAME';
const UPDATE_BILLING_EMAIL = 'UPDATE_BILLING_EMAIL';
const UPDATE_BILLING_PHONE = 'UPDATE_BILLING_PHONE';
const UPDATE_BILLING_ADDRESS = 'UPDATE_BILLING_ADDRESS';
const UPDATE_BILLING_STATE = 'UPDATE_BILLING_STATE';
const UPDATE_BILLING_ZIPCODE = 'UPDATE_BILLING_ZIPCODE';
const UPDATE_CCNUMBER = 'UPDATE_CCNUMBER';
const UPDATE_EXPIRE_DATE = 'UPDATE_EXPIRE_DATE';
const UPDATE_CVV = 'UPDATE_CVV';

export function addStudent(student) {
  return {
    type: ADD_STUDENT,
    student
  };
}

export function updateCartDiscount(discount) {
  return {
    type: UPDATE_CART_DISCOUNT,
    discount
  };
}

export function updateCartAppliedDiscount(appliedDiscount) {
  return {
    type: UPDATE_CART_APPLIED_DISCOUNT,
    appliedDiscount
  };
}


export function updateCartSubtotal(subtotal) {
  return {
    type: UPDATE_CART_SUBTOTAL,
    subtotal
  };
}
export function updateCartTotal(total) {
  return {
    type: UPDATE_CART_TOTAL,
    total
  };
}

export function updateBillingFirstName(billingFirstName) {
  return {
    type: UPDATE_BILLING_FIRSTNAME,
    billingFirstName
  };
}

export function updateBillingLastName(billingLastName) {
  return {
    type: UPDATE_BILLING_LASTNAME,
    billingLastName
  };
}

export function updateBillingEmail(billingEmail) {
  return {
    type: UPDATE_BILLING_EMAIL,
    billingEmail
  };
}

export function updateBillingPhone(billingPhone) {
  return {
    type: UPDATE_BILLING_PHONE,
    billingPhone
  };
}

export function updateBillingAddress(billingAddress) {
  return {
    type: UPDATE_BILLING_ADDRESS,
    billingAddress
  };
}

export function updateBillingState(billingState) {
  return {
    type: UPDATE_BILLING_STATE,
    billingState
  };
}

export function updateBillingZipCode(billingZipCode) {
  return {
    type: UPDATE_BILLING_ZIPCODE,
    billingZipCode
  };
}

export function updateCCNumber(ccNumber) {
  return {
    type: UPDATE_CCNUMBER,
    ccNumber
  };
}

export function updateExpireDate(expireDate) {
  return {
    type: UPDATE_EXPIRE_DATE,
    expireDate
  };
}

export function updateCVV(cvv) {
  return {
    type: UPDATE_CVV,
    cvv
  };
}

const defaultCart = {
  students: [],
  discount: 0,
  appliedDiscount: 0,
  subtotal: 0,
  total: 0,
  billingFirstName: '',
  billingLastName: '',
  billingEmail: '',
  billingPhone: '',
  billingAddress: '',
  billingState: '',
  billingZipCode: '',
  ccNumber: '',
  expireDate: '',
  cvv: ''
};

function cart(state=defaultCart, action) {
  switch (action.type) {
  case UPDATE_CART_DISCOUNT: {
    return {
      ...state,
      discount: action.discount
    };
  }
  case UPDATE_CART_APPLIED_DISCOUNT: {
    return {
      ...state,
      appliedDiscount: action.appliedDiscount
    };
  }
  case UPDATE_CART_SUBTOTAL: {
    return {
      ...state,
      subtotal: action.subtotal
    };
  }
  case UPDATE_CART_TOTAL: {
    return {
      ...state,
      total: action.total
    };
  }
  case UPDATE_BILLING_FIRSTNAME: {
    return {
      ...state,
      billingFirstName: action.billingFirstName
    };
  }
  case UPDATE_BILLING_LASTNAME: {
    return {
      ...state,
      billingLastName: action.billingLastName
    };
  }
  case UPDATE_BILLING_EMAIL: {
    return {
      ...state,
      billingEmail: action.billingEmail
    };
  }
  case UPDATE_BILLING_PHONE: {
    return {
      ...state,
      billingPhone: action.billingPhone
    };
  }
  case UPDATE_BILLING_ADDRESS: {
    return {
      ...state,
      billingAddress: action.billingAddress
    };
  }
  case UPDATE_BILLING_STATE: {
    return {
      ...state,
      billingState: action.billingState
    };
  }
  case UPDATE_BILLING_ZIPCODE: {
    return {
      ...state,
      billingZipCode: action.billingZipCode
    };
  }
  case UPDATE_CCNUMBER: {
    return {
      ...state,
      ccNumber: action.ccNumber
    };
  }
  case UPDATE_EXPIRE_DATE: {
    return {
      ...state,
      expireDate: action.expireDate
    };
  }
  case UPDATE_CVV: {
    return {
      ...state,
      cvv: action.cvv
    };
  }
  case ADD_STUDENT: {
    return {
      ...state,
      students: [
        ...state.students,
        action.student
      ]
    };
  }
  default:
    return state;
  }
}

const thisCart = combineReducers({
  cart
});

export default thisCart;
