import { useEffect } from 'react';
import ReactGA from 'react-ga4';

const usePageTracking = () => {
  useEffect(() => {
    ReactGA.initialize('G-MC9E7KGWTY');
    // *** Enable Test Mode when Testing *** //
    // ReactGA.initialize('G-MC9E7KGWTY', { testMode: true });
    // console.log('Google Analytics is tracking this page.');
  });
};
export default usePageTracking;
