/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

// const queryParams = new URLSearchParams(window.location.search);
// console.log(queryParams.id);
// const id = queryParams.get('id');

function PreorderTest() {
  const [thisCourse, setthisCourse] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const { id } = useParams();
  console.log(id);
  const today = new Date();
  console.log(today);
  const getTestCourse = async () => {
    try {
      const response = await fetch(
        // -------Staging endpoint-------
        `https://pp1chtzivl.execute-api.us-east-1.amazonaws.com/v1/partner/courses/courseById/${id}`
        // -------Prod endpoint--------
        // `https://eykbj2uxie.execute-api.us-east-1.amazonaws.com/v1/partner/courses/courseById/${id}`
        // -----Dev Endpoint (must change gateway)---------
        // 'http://aws:4566/restapis/var6ll8uor/v1/_user_request_/partner/courses/partnersListedCourses/1'
      );
      const jsonData = await response.json();
      return jsonData;
    } catch (err) {
      console.error(err.message);
    }
  };
  useEffect(() => {
    const getCourseData = async () => {
      try {
        const courseData = await getTestCourse();
        setthisCourse(courseData);
        setLoading(false);
      } catch (error) {
        console.error(error.message);
      }
    };

    if (id) {
      // Only fetch course data if 'id' is available in the URL
      getCourseData();
    }
  }, [id]); // Add 'id' as a dependency to re-trigger the effect when the 'id' changes

  useEffect(async () => {
    const courseList = await getTestCourse();
    setthisCourse(courseList);
    setLoading(false);
  }, []);
  console.log(thisCourse);
  localStorage.setItem('stripe-price', thisCourse.stripePrice);

  const [numberOfStudents, setCount] = useState(1);

  function isTwoWeeksOrMoreAway(startDate) {
    const twoWeeksInMilliseconds = 14 * 24 * 60 * 60 * 1000;
    const today = new Date();
    return startDate - today >= twoWeeksInMilliseconds;
  }

  function discount() {
    let discountTotal;
    if (isTwoWeeksOrMoreAway(startDate)) {
      // Apply the discount only if the start_date is two weeks or more away
      discountTotal = numberOfStudents * 50;
    } else {
      discountTotal = 0;
    }
    return discountTotal;
  }

  const startDate = new Date(thisCourse.start_date);
  const endDate = new Date(thisCourse.end_date);

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  function formatDate(date) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  }

  const cartTotal = numberOfStudents * thisCourse.price - discount();

  return (
    <div className='pt-5 pt-lg-5 pb-5'>
      {isLoading ? (
        <div className='d-flex justify-content-center'>
          <div className='spinner-border' role='status'>
            {/* <span className='visually-hidden'>Loading...</span> */}
          </div>
        </div>
      ) : (
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 col-md-7 col-12'>
              <div className='mb-4'>
                <h2 className='h4'>Selected Course</h2>
              </div>
              {/* card header */}

              <div className='card table-responsive'>
                {/* table */}
                <table className='table mb-0'>
                  <thead>
                    <tr>
                      <th
                        className='text-uppercase
                    border-bottom border-top-0'
                      ></th>
                      <th className='text-uppercase border-bottom border-top-0'>
                      Price
                      </th>
                      <th className='border-bottom border-top-0'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* product */}
                    <tr>
                      <td>
                        <div className='d-flex align-items-center align-middle'>
                          {/* <img src={thisCourse.courseBadge} alt='' width='250' /> */}
                          <div className='ms-3'>
                            <div className='h4'>{thisCourse.name}</div>
                            <span className='text-uppercase'>
                              {formattedStartDate} - {formattedEndDate}
                              <br />
                              {thisCourse.start_time} - {thisCourse.end_time}
                              <br />
                            </span>
                            {/* {thisCourse.inPerson ? (
                            <img
                              src={thisCourse.locationBadge}
                              className='courseBrands'
                              alt={thisCourse.imgAltTag}
                            />
                          ) : (
                            <img
                              src={thisCourse.locationBadge}
                              className='courseBrands'
                              alt={thisCourse.imgAltTag}
                            />
                          )} */}
                            <br />
                            {thisCourse.inPerson ? 'In Person' : 'Online Class'}
                          </div>
                        </div>
                      </td>
                      <td className='text-dark align-top h5'>
                      ${thisCourse.price}.00
                      </td>
                      <td className='align-middle'>
                        <a href='#' className='text-reset font-24'>
                          <i className='fe fe-x-circle'> </i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='accordion' id={`accordion${thisCourse.id}`}>
                <div className='card'>
                  <div className='card-header' id={`heading${thisCourse.id}`}>
                    <h2 className='mb-0'>
                      <button
                        className='btn btn-link btn-block text-left'
                        type='button'
                        data-toggle='collapse'
                        data-target={`#collapse${thisCourse.id}`}
                        aria-expanded='true'
                        aria-controls={`collapse${thisCourse.id}`}
                      >
                      COURSE DETAILS{' '}
                        <i
                          className='fa fa-angle-double-right'
                          aria-hidden='true'
                        ></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id={`collapse${thisCourse.id}`}
                    className='collapse'
                    aria-labelledby={`heading${thisCourse.id}`}
                    data-parent={`#accordion${thisCourse.id}`}
                  >
                    <div
                      className='card-body'
                      dangerouslySetInnerHTML={{
                        __html: thisCourse.description
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            {/* Order Summary Section */}
            <div className='col-lg-4 col-md-5 col-12 mt-lg-0 mt-5'>
              <div className='mb-4'>
                <h2 className='h4'>Order summary</h2>
              </div>
              {/* card */}
              <div className='card'>
                {/* list */}
                <ul className='list-group list-group-flush'>
                  <li
                    className='list-group-item d-flex
                  justify-content-between align-items-center p-3'
                  >
                    <div className='mb-0 h6'>Subtotal</div>
                    <span className='h6'>
                      {numberOfStudents} X ${thisCourse.price}.00
                    </span>
                  </li>
                  {/* list */}
                  <li
                    className='list-group-item d-flex
                  justify-content-between align-items-center p-3'
                  >
                    <div className='mb-0 h6'>Discount</div>
                    <span className='text-danger h6'>- ${discount()}.00</span>
                  </li>
                  {/* list */}
                  <li
                    className='list-group-item d-flex
                  justify-content-between align-items-center p-3'
                  >
                    <div className='mb-0 h6'>Total</div>
                    <span className='text-success h6'>
                    ${cartTotal.toLocaleString()}.00
                    </span>
                  </li>
                  {/* list */}
                  <li className='list-group-item p-3'>
                    <div className='d-grid'>
                      <div className='h6 text-center'>
                      Student(s) added in the next step.
                      </div>
                      <div className='d-flex justify-content-center'>
                        <Link
                          to={'testRegister' + thisCourse.id}
                          className='shadow btn btn-primary'
                        >
                        CONTINUE TO ADD STUDENT(S)
                        </Link>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default PreorderTest;
