import React from 'react';

function CiCdFlow() {
  return (
    <div className='container lightGreyContainer'>
      <div className='row'>
        <div className='col-md-6 col-sm-8 text-left'>
          <div className='display-5-bold'>
            Continuous Integrations &amp;<br/>
            Continuous Deployments
          </div>
          <div className='h4 pt-3'>
          Leveraging tools to boost productivity,
          achieving quicker Software Deployments and Continuous Innovation
          </div>
          <div>
            <img src='./assets/cicd_logos.svg'
              alt=''
              className='img-fluid pt-4 p-2'
              width={585} height={178} />
          </div>
        </div>
        <div className='col pl-5'>
          <div className="cicdCard">
            <img src="./assets/cicd_process.jpg"
              className="img-fluid"
              alt=""
              width={472} height={382} />
            <div className="card-img-overlay">
              <div className='pt-md-5 mt-md-5 text-center'>
                {/* <!-- Button trigger modal --> */}
                <button type="button"
                  className="btn btn-light btn-lg"
                  data-toggle="modal" data-target="#cicdModal">
                  <i className="bi bi-eye-fill pr-2"></i>CLICK TO DEMO
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div className="modal fade" id="cicdModal"
        tabIndex="-1" aria-labelledby="CiCdProcess" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close"
                data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <img src="./assets/ci_cd_flow.webp"
                className="img-fluid" alt=""
                width={1224}
                height={1964}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CiCdFlow;
