/* eslint-disable max-len */
function CPSteps() {
  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col">
          <div className="row step">
            <div className="col how-left-column">
              <h1 className="step-number" data-testid="step-one-number">
                1
              </h1>
              <h2 className="step-title" data-testid="step-one-title">
                Browse your courses
              </h2>
              <h3
                className="step-description"
                data-testid="step-one-description"
              >
                Choose a training course that helps you upskill and pay later
                with <a href="https://www.careerpay.com/" target='none'>CareerPay</a>. You’ll see us at checkout.
              </h3>
            </div>
            <div className="col">
              <img
                src="./assets/phone_3.jpg"
                alt="phone step one"
                className="steps-phone"
                data-testid="step-one-image"
              />
            </div>
          </div>
          {/* Second Step------- */}
          <div className="row step-two">
            <div className="col how-left-column">
              <img
                src="./assets/phone_1.jpg"
                alt="phone step two"
                className="steps-phone"
                data-testid="step-two-image"
              />
            </div>
            <div className="col">
              <h1 className="step-number" data-testid="step-two-number">
                2
              </h1>
              <h2 className="step-title" data-testid="step-two-title">
                Payment plans according to course cost
              </h2>
              <h3
                className="step-description"
                data-testid="step-two-description"
              >
                Our payment plans are broken down into four, five, or six monthly installments based on the total price of the course, with the first installment due at the time of course registration.
                {/* You’re in control. Pick the payment option that works for you
                and your budget—from 4 interest-free payments every 2 weeks to
                monthly installments. */}
              </h3>
            </div>
          </div>
          {/* Third Step------- */}
          <div className="row step">
            <div className="col how-left-column">
              <h1 className="step-number" data-testid="step-three-number">
                3
              </h1>
              <h2 className="step-title" data-testid="step-three-title">
                Pay at your convenience
              </h2>
              <h3
                className="step-description"
                data-testid="step-three-description"
              >
                Manage your payments online on the <a href="https://www.careerpay.com/" target='none'>CareerPay</a> website or on the upcoming app for quick
                and easy transactions.
              </h3>
            </div>
            <div className="col">
              <img
                src="./assets/phone_2.jpg"
                alt="phone step three"
                className="steps-phone"
                data-testid="step-three-image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CPSteps;
