import React from 'react';

function Sba() {
  return (
    <>
      <div>
        <div className='mb-1 mt-4'>
          DUNS #968907076<br/>
          SAM UEI #WD4UD1FWVYL3<br/>
          CAGE CODE 6HR48
        </div>
        {/* <img src='./assets/sba_trophy.png'
          alt='Trophy' className='sbaTrophy'/> */}
      </div>
    </>
  );
}
export default Sba;
