/* eslint-disable react/prop-types */
const CreditCardPayment = ({
  ccNumber,
  handleCCNumberChange,
  expireDate,
  handleExpireDateChange,
  cvv,
  handleCVVChange
}) => {
  return (
    <div className='container'>
      <div className='row mt-5'>
        <div className='mb-3 col-lg-6 col-md-12'>
          <label htmlFor='cc-mask' className='form-label '>
            * Card Number (no spaces)
          </label>
          <input
            type='text'
            className='form-control cc-inputmask'
            id='ccNumber'
            name='ccNumber'
            placeholder='15 or 16 digit number'
            maxLength='16'
            // pattern='^\d{4}\d{4}\d{4}\d{4}$'
            value={ccNumber}
            onChange={handleCCNumberChange}
            required
          />
          <span>
            <img src='./assets/visa.svg' title='Visa' className='mr-2' />
            <img
              src='./assets/mastercard.svg'
              title='Mastercard'
              className='mr-2'
            />
            <img
              src='./assets/amex.svg'
              title='American Express'
              className='mr-2'
            />
            <img
              src='./assets/discover.svg'
              className='discoverCardIcon'
              title='Discover'
            />
          </span>
        </div>
        <div className='mb-3 col-12 col-lg-6 col-md-12'>
          <label className='form-label'>* Expiration (MM/YY)</label>
          <input
            type='text'
            className='form-control'
            name='expireDate'
            id='expireDate'
            placeholder='MM/YY'
            pattern='^\d{2}\/\d{2}$'
            maxLength='5'
            value={expireDate}
            onChange={handleExpireDateChange}
            required
          />
        </div>
        <div className='mb-3 mt-3 col-12 col-lg-6 col-md-12'>
          <label htmlFor='cvv' className='form-label '>
            * CVV Code
          </label>
          <input
            type='text'
            className='cvv form-control'
            name='cvv'
            id='cvv'
            placeholder='Required'
            maxLength='4'
            value={cvv}
            onChange={handleCVVChange}
            required
          />
          <img
            src='./assets/cvv.svg'
            className='cvvIcon ml-2'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title='A 3 - digit number,
            typically printed on the back of a card.'
            data-bs-original-title='A 3 - digit number,
            typically printed on the back of a card.'
          />
        </div>
      </div>
    </div>
  );
};

export default CreditCardPayment;
