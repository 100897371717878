import React, { useEffect, useState } from 'react';
import Menubar from '../components/Menubar';
import Footer from '../components/Footer';
import Card from 'react-bootstrap/Card';
import usePageTracking from '../components/PageTracking';
import Healthform from '../components/Healthform';
import updateMetaDescription from '../components/DescriptTag';
import updateMetaKeywords from '../components/KeywordTag';
import ApplyToday from '../components/ApplyToday';

function Healthcare() {
  // eslint-disable-next-line max-len
  const [pageTitle, setPageTitle] = useState('Healthcare Staffing | Joint Commission Certified');
  useEffect(() => {
    document.title = pageTitle; // Set the page title to the current state value
  }, [pageTitle]);

  // Update meta description dynamically
  useEffect(() => {
  // eslint-disable-next-line max-len
    updateMetaDescription('Our team of certified professionals ensure top qualified candidates in the healthcare field to meet your staffing demands.');
  }, []);

  // Update meta keywords dynamically
  useEffect(() => {
    // eslint-disable-next-line max-len
    updateMetaKeywords('clinical, nurse, nursing, contractors, hiring, medical, services, certified');
  }, []);

  usePageTracking();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const jcp = './assets/pdf/joint_commmission_policy.pdf';

  return (
    <>
      <Menubar />
      {/* Hero */}
      <div className='py-lg-20 pt-14 pb-10 bg-cover healthcareHeroImage'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-6 col-lg-7 col-md-8 col-12'>
              <div className='text-white'>
                <h1 className='text-white mb-3 display-4 whiteText-responsive'>
                  HEALTHCARE STAFFING
                </h1>
                {/* <p className='mb-3 pe-lg-12 pe-0 lead'>
                We continually meet or exceed the standards established by
                the Joint Commission. These standards provide the highest
                quality and best value across all healthcare settings.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Page Content */}
      <div className='lightGreyContainer'>
        <div className='row'>
          <div className='col'>
            <div className='row'>
              <div className='col text-center'>
                <img src='./assets/joint_commission_lg.webp'
                  alt='Joint Commission'
                  width={344}
                  height={344} />
              </div>
              <div className='col'>
                <p className='display-4'>
                  Joint Commission Certified
                </p>
                <p className='leadGrey'>We are committed to
                provide a higher standard of service and to
                the delivery of safe, quality patient care.
                </p>
                <p className='leadGrey'>As our customer,
                  you can have the confidence that
                  we continually meets or exceeds the
                  standards established by the Joint Commission. These
                  standards provide the highest quality and best value across
                  all healthcare settings. This is supported and maintained by
                  processes within Appddiction Studio.</p>
                <br />
                <div className='leadGrey'>
                  <a href={jcp}>
                    Download Our Policy Statement
                    <i className='fa fa-angle-right p-1' aria-hidden='true'></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Medical Services Cards */}
      <div className='whiteContainer'>
        <div className='p-3'>
          <h1 className='text-center pb-3'>Medical Services</h1>
          <div className='container-fluid'>
            <div className='row justify-content-center'>
              {/* Cards start here */}
              <Card id='devSecOpsCard'>
                <Card.Body className='d-flex flex-column p-0'>
                  <Card.Title id='medServicesTitle'>SKILLED NURSING
                  </Card.Title>
                  <Card.Text id='medServicesText'>Providing a broad range of
                    skilled nursing such as Nursing Aides
                    and Nurse Practitioners providing
                    inpatient and outpatient care
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card id='devSecOpsCard'>
                <Card.Body className='d-flex flex-column p-0'>
                  <Card.Title id='medServicesTitle'>LAB SERVICES
                  </Card.Title>
                  <Card.Text id='medServicesText'>Providing services for testing
                    specimens from the body that are used to
                    diagnose and treat patients.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card id='devSecOpsCard'>
                <Card.Body className='d-flex flex-column p-0'>
                  <Card.Title id='medServicesTitle'>CLINICAL PSYCHOLOGIST
                  </Card.Title>
                  <Card.Text id='medServicesText'>Providing comprehensive
                    mental and behavioral care for individuals and families
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card id='devSecOpsCard'>
                <Card.Body className='d-flex flex-column p-0'>
                  <Card.Title id='medServicesTitle'>CLINICAL PHARMACIST
                  </Card.Title>
                  <Card.Text id='medServicesText'>Providing direct patient
                    care that optimizes the use of medication to provide
                    health, wellness &amp; disease prevention
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card id='devSecOpsCard'>
                <Card.Body className='d-flex flex-column p-0'>
                  <Card.Title id='medServicesTitle'>PEBLO TECHS
                  </Card.Title>
                  <Card.Text id='medServicesText'>Providing information,
                  assistance, and case status updates to the affected
                  Service Member throughout the Disability Evaluation
                  System process
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card id='devSecOpsCard'>
                <Card.Body className='d-flex flex-column p-0'>
                  <Card.Title id='medServicesTitle'>LAB TECHNICIAN
                  </Card.Title>
                  <Card.Text id='medServicesText'>Providing services for
                    recording data, protecting the accuracy and efficiency
                    of scientific experiments, maintaining equipment and
                    lab tools and keeping the lab organized
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card id='devSecOpsCard'>
                <Card.Body className='d-flex flex-column p-0'>
                  <Card.Title id='medServicesTitle'>SOCIAL SERVICES
                  </Card.Title>
                  <Card.Text id='medServicesText'>Providing services to
                    protect vulnerable children and support families
                    in need of assistance
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card id='devSecOpsCard'>
                <Card.Body className='d-flex flex-column p-0'>
                  <Card.Title id='medServicesTitle'>PATIENT SITTERS
                  </Card.Title>
                  <Card.Text id='medServicesText'>Providing comfort for
                    patients and looking out for their safety and ensuring
                    they&apos;re satisfied with the level of care they&apos;re
                    receiving
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card id='devSecOpsCard'>
                <Card.Body className='d-flex flex-column p-0'>
                  <Card.Title id='medServicesTitle'>OPTHALMOLOGY
                  </Card.Title>
                  <Card.Text id='medServicesText'>Providing services for
                    diagnosing and treating all eye diseases, including
                    eye surgery, exams and prescriptions for eyeglasses
                    and contact lenses
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card id='devSecOpsCard'>
                <Card.Body className='d-flex flex-column p-0'>
                  <Card.Title id='medServicesTitle'>INFORMATICS
                  </Card.Title>
                  <Card.Text id='medServicesText'>Health informatics
                    professionals use their knowledge of healthcare,
                    information systems, databases and information
                    technology security to gather, store, interpret
                    and manage the massive amount of data generated
                    when care is provided to patients
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card id='devSecOpsCard'>
                <Card.Body className='d-flex flex-column p-0'>
                  <Card.Title id='medServicesTitle'>FACILITY MANAGEMENT
                  </Card.Title>
                  <Card.Text id='medServicesText'>Providing services to
                    oversee financial, technical and administrative
                    operations within a healthcare organization. They
                    must plan, organize, implement, evaluate and monitor
                    all facility programs and departments
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card id='devSecOpsCard'>
                <Card.Body className='d-flex flex-column p-0'>
                  <Card.Title id='medServicesTitle'>BIO-MEDICAL TECH
                  </Card.Title>
                  <Card.Text id='medServicesText'>Providing maintenance
                    to service medical equipment and work on diagnostic
                    and treatment devices, such as CAT scanners and
                    heart defibrillators
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card id='devSecOpsCard'>
                <Card.Body className='d-flex flex-column p-0'>
                  <Card.Title id='medServicesTitle'>MEDICAL RECORDS TECHNICIAN
                  </Card.Title>
                  <Card.Text id='medServicesText'>Ensuring the accurate
                    management, organization, and transcription of
                    patient&apos;s medical histories, symptoms, diagnoses,
                    and treatments using the customer&apos;s
                    preferred medical records systems
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card id='devSecOpsCard'>
                <Card.Body className='d-flex flex-column p-0'>
                  <Card.Title id='medServicesTitle'>MEDICAL LOGISTIC TECHNICIAN
                  </Card.Title>
                  <Card.Text id='medServicesText'>Providing warehouse management
                    of medical supply inventory and coordination of delivery
                    and maintenance
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card id='devSecOpsCard'>
                <Card.Body className='d-flex flex-column p-0'>
                  <Card.Title id='medServicesTitle'>OPTOMETRY TECHNICIAN
                  </Card.Title>
                  <Card.Text id='medServicesText'>Assisting and working closely
                    with optometrists, and perform the administrative duties
                    and assist with vision acuity testing
                  </Card.Text>
                </Card.Body>
              </Card>
              {/* Cards end here */}
            </div>
          </div>
        </div>
      </div>
      <ApplyToday />
      <Healthform />
      <Footer />
    </>
  );
}
export default Healthcare;
