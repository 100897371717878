import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

function Auth() {
  const cart = useSelector(state => state.cart);
  const queryParams = new URLSearchParams(window.location.search);
  const auth = queryParams.get('auth');
  const history = useHistory();
  console.log(cart);
  console.log(auth);
  return (
    <>
      <div className='pb-3 mb-3'>
        { auth == 'false' &&
        <>
          <div>
            <p className='receiptTitle'>
              There was an issue processing your order.
            </p>
            <p className='receiptText'>
              Your order was not compeleted. Check the
              information you entered on the previous screen
              and try again.
            </p>
            <button className='shadow btn btn-success btn-lg'
              onClick={() => {
                history.goBack();
              }}
            >
                TRY AGAIN
            </button>
          </div>
        </>
        }
        { auth == 'true' &&
        <>
          <div className='receiptTitle'>
            Your order is complete and you have been registered for class.
          </div>
          <div className='pt-3'>
            <p className='receiptText'>You should be receiving an email
              invitation and order receipt at
            <span className='font-weight-bold'> { cart.billingEmail }</span>
              &nbsp;with next step instructions.
            </p>
            <p className='receiptText'> If you do not receive an invite
            within 24-48 hours please contact us at&nbsp;
            <a href='mailto:training@appddictionstudio.com'>
                training@appddictionstudio.com</a> or <br/> call&nbsp;
            <span className='font-weight-bold'>(210) 859-9677</span>.
            </p>
          </div>
        </>
        }
      </div>
    </>
  );
}
export default Auth;

