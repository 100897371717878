import React from 'react';
import Footer from '../components/Footer';
import Menubar from '../components/Menubar';

function Media() {
  return (
    <>
      <Menubar />
      <h1>This is Media</h1>
      <Footer />
    </>
  );
}
export default Media;
