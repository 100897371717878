/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { updateCartDiscount } from '../store/orderTotal';
import { updateCartAppliedDiscount } from '../store/orderTotal';
import { updateCartSubtotal } from '../store/orderTotal';
import { updateCartTotal } from '../store/orderTotal';
import { useParams, Link } from 'react-router-dom';

function registerStudent({ cart }) {
  const [thisCourse, setthisCourse] = useState([]);
  const [appliedDiscount, setAppliedDiscount] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [numberOfStudents, setNumberOfStudents] = useState(0);
  const [total, setTotal] = useState(numberOfStudents * subtotal);
  const [earlyBirdDiscount, setEarlyBirdDiscount] = useState(0);
  const [discountCode, setDiscountCode] = useState('');
  const [careerPay, setCareerPay] = useState(false);
  const [credit, setCredit] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const getCourse = async () => {
    try {
      const response = await fetch(
        `${window.__ENV__.API_URL}/courses/api/get/${id}`
      );
      const jsonData = await response.json();
      return jsonData;
    } catch (err) {
      console.error(err.message);
    }
  };

  function calculateEarlyDiscount(course, numberOfStudents) {
    let discountTotal;
    if (course.earlyBirdDiscountActive === true) {
      discountTotal = numberOfStudents * course.earlyBirdDiscountAmount;
    } else {
      discountTotal = 0;
    }
    return discountTotal;
  }

  useEffect(async () => {
    const courseList = await getCourse();
    setthisCourse(courseList);
    setSubtotal(courseList.subtotal);
  }, []);

  function applyDiscount() {
    const discountPercentage = 20;
    let discountTotal;
    if (discountCode === 'SHIP20') {
      const discount = (subtotal * discountPercentage) / 100;
      discountTotal = numberOfStudents * discount;
    } else {
      discountTotal = 0;
    }
    setAppliedDiscount(discountTotal);
  }

  function handleDiscountCode(event) {
    const code = event.target.value;
    setDiscountCode(code);
  }

  useEffect(() => {
    console.log(`Students Array Changed. Recalculate.
    ${JSON.stringify(cart.students)}`);
    const calculateEarlyBirdDiscount = calculateEarlyDiscount(
      thisCourse,
      cart.students.length
    );
    const numStudents = cart.students.length;
    let cartTotal;
    if (discountCode === '') {
      cartTotal =
        numStudents * (thisCourse.subtotal || 0) - calculateEarlyBirdDiscount;
    } else {
      cartTotal =
        numStudents * (thisCourse.subtotal || 0) -
        (appliedDiscount + calculateEarlyBirdDiscount);
    }
    setNumberOfStudents(numStudents);
    setEarlyBirdDiscount(calculateEarlyDiscount(thisCourse, numStudents));
    setSubtotal(thisCourse.subtotal);
    setTotal(cartTotal);
    dispatch(updateCartDiscount(calculateEarlyBirdDiscount));
    dispatch(updateCartAppliedDiscount(appliedDiscount));
    dispatch(updateCartSubtotal(subtotal));
    dispatch(updateCartTotal(cartTotal));
  }, [cart.students, appliedDiscount]);

  useEffect(async () => {
    console.log(`Cart Changed.
      ${JSON.stringify(cart)}`);
  }, [cart]);
  console.log(cart.students);

  function handleCreditPayment() {
    setCredit(true);
  }

  function handleCareerPayPayment() {
    setCareerPay(true);
  }

  console.log(credit);
  console.log(careerPay);

  return (
    <div className='col-sm-12'>
      {/* Order Summary Section */}
      <div>
        <div className='mb-4'>
          <h2 className='h4'>Order summary</h2>
        </div>
        {/* card */}
        <div className='card'>
          <img src={thisCourse.courseBadge} className='courseBadges pt-3' />
          <p className='p-3 text-center'>
            <b>{thisCourse.courseTitle}</b>
            <br />
            {thisCourse.durationString}
            <br />
            {thisCourse.startTime} &ndash; {thisCourse.endTime}
            &nbsp;{thisCourse.timezone}
            <br />
            {thisCourse.inPerson ? (
              <img
                src={thisCourse.locationBadge}
                className='courseBrands'
                alt={thisCourse.imgAltTag}
              />
            ) : (
              <img
                src={thisCourse.locationBadge}
                className='courseBrands pt-2'
                alt={thisCourse.imgAltTag}
              />
            )}
          </p>
          {/* list */}
          <ul className='list-group list-group-flush'>
            <li
              className='list-group-item d-flex
              justify-content-between align-items-center p-3'
            >
              <div className='mb-0 h6'>Subtotal</div>
              <span className='h6'>
                {numberOfStudents} X ${subtotal}.00
              </span>
            </li>
            {/* list */}
            <li
              className='list-group-item d-flex
              justify-content-between align-items-center p-3'
            >
              <div className='mb-0 h6'>Early Discount</div>
              <span className='text-danger h6'>-${earlyBirdDiscount}.00</span>
            </li>
            <li
              className='list-group-item d-flex
              justify-content-between align-items-center m-2'
            >
              <input
                type='text'
                value={discountCode}
                onChange={handleDiscountCode}
                placeholder='Enter Discount Code'
                className='form-control m-2'
              />
              <button
                className='shadow btn btn-primary'
                onClick={applyDiscount}
              >
                Apply
              </button>
              {/* <h5 className='mb-0'>Discount</h5>
              <span className='text-success'>- ${discount}.00</span> */}
            </li>
            {/* list */}
            <li
              className='list-group-item d-flex
              justify-content-between align-items-center p-3'
            >
              <div className='mb-0 h6'>Discount</div>
              <span className='text-danger h6'>-${appliedDiscount}.00</span>
            </li>
            {/* list */}
            <li
              className='list-group-item d-flex
              justify-content-between align-items-center p-3'
            >
              <div className='mb-0 h6'>Total</div>
              <span className='text-success h6'>${total}.00</span>
            </li>
            {/* list */}
            {(() => {
              if (cart.students.length > 0) {
                return (
                  <li className='list-group-item p-3'>
                    <div className='d-grid text-center'>
                      <Link
                        to={'/payment' + id}
                        className='shadow btn btn-primary text-uppercase w-75'
                      >
                        Pay with credit card
                      </Link>
                    </div>
                  </li>
                );
              }
            })()}
            {(() => {
              if (cart.students.length > 0) {
                return (
                  <li className='list-group-item p-3'>
                    <div className='d-grid text-center'>
                      <Link
                        to={'/cppayment' + id}
                        className='shadow btn btn-cpay text-uppercase w-75'
                      >
                      Pay with careerPay
                      </Link>
                    </div>
                  </li>
                );
              }
            })()}
          </ul>
        </div>
      </div>
    </div>
  );
}

registerStudent.propTypes = {
  cart: PropTypes.object
};

export default connect(state => ({
  cart: state.cart
}))(registerStudent);
