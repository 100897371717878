import React from 'react';

function Notlisted() {
  return (
    <div className='greenContainer'>
      <div className='row'>
        <div className='col d-flex justify-content-center'>
          <img src='./assets/computer_training.svg'
            className='d-none d-md-block' alt='Person on computer'
            width={263} height={192}/>
        </div>
        <div className='col-lg-8'>
          <p className='h1 text-white'>Need a course that&apos;s
            not listed?</p>
          <p className='lead text-white'>Our experienced training and course
            design team will create the customized training course you need. We
            can create e-learning, hybrid and instructor lead courses.
          </p>
          <a href='#contactForm'
            className='btn btn-outline-light btn-lg'>SEND US YOUR INFO</a>
        </div>
      </div>
    </div>
  );
}
export default Notlisted;
