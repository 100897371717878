import React from 'react';

function Transformcustomers() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className='h1 text-center'>
              Customers
            </div>
          </div>
        </div>
      </div>
      <div className='container d-flex justify-content-center p-2'>
        <div className='row col-12'>
          <div className='col text-center'>
            <img src='./assets/usaa-logo.svg' alt='USAA'
              className='customerLogo'
              width={120} height={120} />
          </div>
          <div className='col text-center pt-4'>
            <img src='./assets/fedex-logo.svg' alt='FedEX'
              className='fedexcustomerLogo'
              width={310} height={83}/>
          </div>
          <div className='col text-center'>
            <img src='./assets/usaf-logo.svg' alt='U.S. Air Force'
              className='usafcustomerLogo'
              width={135} height={105}/>
          </div>
          <div className='col text-center pt-3'>
            <img src='./assets/boeing-logo.svg' alt='Boeing'
              className='boeingcustomerLogo'
              width={170} height={73}/>
          </div>
        </div>
      </div>
      <div className='container d-flex justify-content-center p-2 pb-5'>
        <div className='row col-12'>
          <div className='col text-center'>
            <img src='./assets/salesforce-logo.svg' alt='Salesforce'
              className='customerLogo'
              width={120} height={90} />
          </div>
          <div className='col text-center pt-3'>
            <img src='./assets/novus-logo.png' alt='Novus Professional Services'
              className='novuscustomerLogo'
              width={135} height={54}/>
          </div>
          <div className='col text-center pt-4'>
            <img src='./assets/dell-logo.svg' alt='Dell Computing'
              className='dellcustomerLogo'
              width={135} height={42}/>
          </div>
          <div className='col text-center pt-3'>
            <img src='./assets/james-avery-logo.svg' alt='James Avery Jewelry'
              className='boeingcustomerLogo'
              width={170} height={43}/>
          </div>
        </div>
      </div>
    </>
  );
}
export default Transformcustomers;
