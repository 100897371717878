function updateMetaKeywords(keywords) {
  const metaTag = document.querySelector('meta[name="keywords"]');

  if (metaTag) {
    metaTag.setAttribute('content', keywords);
  } else {
    const newMetaTag = document.createElement('meta');
    newMetaTag.setAttribute('name', 'keywords');
    newMetaTag.setAttribute('content', keywords);
    document.head.appendChild(newMetaTag);
  }
}

export default updateMetaKeywords;
