import React from 'react';

function SecondaryNaicsCodes() {
  return (
    <>
      <div className="container fluid p-5" id="naicscodes">
        <div className="row">
          <div className="col-sm text-center">
            <h3>511210</h3>
            <br/>
            <h3>541513</h3>
            <br/>
            <h3>541612</h3>
            <br/>
            <h3>621111</h3>
            <br/>
            <h3>621399</h3>
            <br/>
            <h3>622110</h3>
            <br/>
            <h3>811219</h3>
            <br/>
          </div>
          <div className="col-sm text-center">
            <h3>519190</h3>
            <br/>
            <h3>541519</h3>
            <br/>
            <h3>611420</h3>
            <br/>
            <h3>621112</h3>
            <br/>
            <h3>621511</h3>
            <br/>
            <h3>811213</h3>
            <br/>
          </div>
          <div className="col-sm text-center">
            <h3>518210</h3>
            <br/>
            <h3>541611</h3>
            <br/>
            <h3>611430</h3>
            <br/>
            <h3>561110</h3>
            <br/>
            <h3>621330</h3>
            <br/>
            <h3>621999</h3>
            <br/>
          </div>
        </div>
      </div>
    </>
  );
}
export default SecondaryNaicsCodes;
