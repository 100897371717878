/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { createCheckoutSession } from '../services/CareerpayService';

function getThisCourse({ paymentType }) {
  console.log(paymentType);
  const [thisCourse, setthisCourse] = useState([]);
  const { id } = useParams();
  const getCourse = async () => {
    try {
      const response = await fetch(
        `${window.__ENV__.API_URL}/courses/api/get/${id}`
      );
      const jsonData = await response.json();
      return jsonData;
    } catch (err) {
      console.error(err.message);
    }
  };
  useEffect(async () => {
    const courseList = await getCourse();
    setthisCourse(courseList);
  }, []);

  const handleCheckout = async priceId => {
    try {
      const stripeId = sessionStorage.getItem('stripeId').replace(/['"]+/g, '');
      const res = await createCheckoutSession(priceId, stripeId);
      const { url } = res;
      window.location = url;
    } catch (e) {
      console.error('🚀 ~ file: Courses.jsx:25 ~ handleCheckout ~ e', e);
    }
  };

  const cart = useSelector(state => state.cart);
  const students = useSelector(state => state.cart.students);
  console.log(cart);
  console.log(students);

  const history = useHistory();

  const submitPayment = async e => {
    e.preventDefault();
    console.log('...sending data to API');
    const apiResponse = await fetch(
      'https://credit-card-service.appddiction1.com/credit-card/transactions/create-custom-usd-transaction?appName=appddiction-studio-dot-com',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          firstName: cart.billingFirstName,
          lastName: cart.billingLastName,
          companyName: cart.company,
          email: cart.billingEmail,
          creditCardNumber: cart.ccNumber,
          expirationDate: cart.expireDate,
          ccv: cart.cvv,
          description: `${thisCourse.courseTitle}, ${thisCourse.durationString}`,
          creditCardApproved: true,
          zipCode: cart.billingZipCode,
          invoiceNumber: cart.billingZipCode,
          invoiceAmount: cart.total,
          allStudentsArray: students,
          selectedCourse: {
            courseTitle: thisCourse.courseTitle,
            startDate: thisCourse.durationString,
            endDate: thisCourse.endDate,
            startTime: thisCourse.startTime,
            endTime: thisCourse.endTime,
            timezone: thisCourse.timezone,
            startDay: thisCourse.startDay,
            endDay: thisCourse.endDay,
            total: cart.total,
            subtotal: cart.subtotal,
            year: thisCourse.year
          },
          phone: cart.billingPhone
        })
      }
    );
    const orderComplete = await apiResponse.json();
    console.log(`Response from Authorize.net API:
      ${orderComplete}`);
    // console.log(JSON.stringify(orderComplete));
    history.push(`/authorize?auth=${orderComplete}`);
  };

  // const navHome = e => {
  //   e.preventDefault();
  //   history.push('/authorize?auth=false');
  // };

  return (
    <div className="col-sm-12">
      {/* Order Summary Section */}
      <div>
        <div className="mb-4">
          {/* <button className='btn btn-primary'
            onClick={navHome}
          >
            useHistory
          </button> */}
          <h2 className="h4">Order summary</h2>
        </div>
        {/* card */}
        <div className="card">
          <form onSubmit={submitPayment}>
            <img src={thisCourse.courseBadge} className="courseBadges pt-3" />
            <p className="p-3 text-center">
              <b>{thisCourse.courseTitle}</b>
              <br />
              {thisCourse.durationString}
              <br />
              {thisCourse.startTime} &ndash; {thisCourse.endTime}
              &nbsp;{thisCourse.timezone}
              <br />
              {thisCourse.inPerson ? (
                <img
                  src={getCourse.locationBadge}
                  className="courseBrands"
                  alt={thisCourse.imgAltTag}
                />
              ) : (
                <img
                  src={thisCourse.locationBadge}
                  className="courseBrands pt-2"
                  alt={thisCourse.imgAltTag}
                />
              )}
            </p>
            {/* list */}
            <ul className="list-group list-group-flush">
              <li
                className="list-group-item d-flex
              justify-content-between align-items-center p-3"
              >
                <div className="mb-0 h6">Subtotal</div>
                <span className="h6">
                  {cart.students.length} X ${thisCourse.subtotal}.00
                </span>
              </li>
              {/* list */}
              <li
                className="list-group-item d-flex
              justify-content-between align-items-center p-3"
              >
                <div className="mb-0 h6">Early Discount</div>
                <span className="text-danger h6">-${cart.discount}.00</span>
              </li>
              {/* list */}
              <li
                className="list-group-item d-flex
              justify-content-between align-items-center p-3"
              >
                <div className="mb-0 h6">Applied Discount</div>
                <span className="text-danger h6">
                  -${cart.appliedDiscount}.00
                </span>
              </li>
              {/* list */}
              <li
                className="list-group-item d-flex
              justify-content-between align-items-center p-3"
              >
                <div className="mb-0 h6">Total</div>
                <span className="text-primary h6">${cart.total}.00</span>
              </li>
              <li className="list-group-item p-3">
                <div className="d-grid text-center">
                  {paymentType === 'credit' ? (
                    <div>
                      <button
                        // eslint-disable-next-line max-len
                        className="shadow btn btn-success payment-btn text-uppercase"
                        onClick={submitPayment}
                        // onTouchEnd={submitPayment}
                      >
                        PAY THIS ENTIRE AMOUNT
                      </button>
                    </div>
                  ) : (
                    <button
                      className="shadow btn btn-success text-uppercase"
                      onClick={() =>
                        handleCheckout(thisCourse.courseDescription)
                      }
                    >
                      PAY IN INSTALLMENTS
                    </button>
                  )}
                </div>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  );
}
getThisCourse.propTypes = {
  cart: PropTypes.object
};

export default connect(state => ({
  cart: state.cart
}))(getThisCourse);
