import React from 'react';
import Card from 'react-bootstrap/Card';

function MVH() {
  return (
    <div>
      <Card>
        <h1>This is MVH</h1>
        <h1>Mission Statement</h1>
        <h5>
          For Appddiction Studio, LLC who engages in professional services for
          commercial and federal agencies the Appddiction Studio Lean-Agile
          Center of Excellence is a self-organizing, cross-functional,
          lean-agile transformational management team that drives the
          transformation of our enterprise to a Lean-Agile way, using the Scaled
          Agile framework unlike traditional waterfall approaches and methods we
          are trained, certified, mentored agile practitioners committed to
          providing and implementing training, process, technology, tooling,
          culture, governance and contracting changes needed to achieve the
          business value and benefits of a Lean-Agile way of working.
        </h5>
      </Card>
    </div>
  );
}
export default MVH;
