/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import './Menubar.css';

// IntroCards component renders a carousel and a row of cards showcasing different services
function IntroCards() {
  // Carousel component with 5 items, each containing an image and a description
  return (
    <>
      <div id="myCarousel" className="carousel slide" data-ride="carousel">
        {/* Carousel indicators */}
        <ol className="carousel-indicators">
          {/* Indicator for each slide */}
          <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
          <li data-target="#myCarousel" data-slide-to="1"></li>
          <li data-target="#myCarousel" data-slide-to="2"></li>
          <li data-target="#myCarousel" data-slide-to="3"></li>
          <li data-target="#myCarousel" data-slide-to="4"></li>
        </ol>
        {/* Carousel inner component containing the slides */}
        <div className="carousel-inner">
          {/* First active slide */}
          <div className="carousel-item active">
            <img src="./assets/devsecops_hero.webp"
              alt='Integrate Security seamlessly into Development & Operations, turn it into an innovation catalyst'/>
            <div className="container">
              <div className="carousel-caption text-left">
                {/* Hero title and a link to the DevSecOps page */}
                <div className='heroTitle'>Integrate Security seamlessly into Development & Operations, turn it into an innovation catalyst</div>
                <div><Link className="btn btn-sm btn-outline-light mt-5" to="/devsecops" role="button" tabIndex="0">START INTEGRATING TODAY</Link></div>
              </div>
            </div>
          </div>
          {/* Other slides */}
          {/* ... (other slides follow the same structure) */}
          <div className="carousel-item">
            <img src="./assets/softdev_hero.webp"
              alt='Where ideas evolve into digital reality, one line of code at a time' />
            <div className="container">
              <div className="carousel-caption text-left">
                <div className='heroTitle'>Where ideas evolve into digital reality, one line of code at a time</div>
                <div><Link className="btn btn-sm btn-outline-light mt-5" to="/softdev" role="button">LET&apos;S BUILD SOMETHING</Link></div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img src="./assets/training_hero_dark.webp"
              alt='Adapt, collaborate, and deliver excellence in the fast-paced world of software development'/>
            <div className="container">
              <div className="carousel-caption text-left">
                <div className='heroTitle whiteText'>Adapt, collaborate, and deliver excellence in the fast-paced world of software development</div>
                <div><Link className="btn btn-sm btn-outline-light mt-5" to="/upcoming" role="button">START TRAINING TODAY</Link></div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img src="./assets/transform_hero.webp" />
            <div className="container">
              <div className="carousel-caption text-left">
                <div className='heroTitle blackText'>Improve flexibility, collaboration, and responsiveness in delivering value to customers</div>
                <div><Link className="btn btn-sm btn-outline-dark mt-5" to="/transformation" role="button">START TRANSFORMING TODAY</Link></div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img src="./assets/nocode_hero.webp" />
            <div className="container">
              <div className="carousel-caption text-left">
                <div className='heroTitle'>Create software applications more efficiently, accelerating development, reducing barriers to digital innovation</div>
                <div><Link className="btn btn-sm btn-outline-light mt-5" to="/low-code" role="button">START CREATING TODAY</Link></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Container for the row of cards showcasing different services */}
      <div className='container-fluid' data-testid='introcards'>
        <div className='row justify-content-center'>
          <div className="card" id="introCard">
            <div className="card-body d-flex flex-column" id="card-body">
              {/* Card title */}
              <div className="sitecard-title">
                  DEVSECOPS/ SOFTWARE DEVELOPMENT
              </div>
              {/* Card text */}
              <div className="card-text" id="card-text"
                data-testid="devCardText">
                Using the right tools at the right time,
                our team accelerates delivery and drives
                value while incorporating security into
                every step of the software development process.
              </div>
              {/* Link and button for navigating to the DevSecOps page */}
              <Link to="/devsecops" className="mt-auto">
                <button className="btn btn-outline-light button"
                  aria-label='Navigate to Dev Sec Ops and Software Development'>
                  LET&apos;S BUILD SOMETHING</button>
              </Link>
            </div>
          </div>
          {/* Other card components follow the same structure */}
          {/* ... (other card components) */}
          <div className="card" id="introCard">
            <div className="card-body d-flex flex-column" id="card-body2">
              <div className="sitecard-title">
                AGILE &amp; DEVSECOPS TRAINING
              </div>
              <div className="card-text"
                id="card-text" data-testid="trainingCardText">
                  Foster an Agile mindset by learning Agile principles
                  and methods to lead self-managed cross-collaborative
                  teams to achieve business goals.
              </div>
              <Link to="/upcoming" className="mt-auto">
                <button className="btn btn-outline-light button"
                  aria-label='Navigate to Agile and Dev  Sec Ops Training'>
                START UPSKILLING TODAY</button>
              </Link>
            </div>
          </div>
          <div className="card" id="introCard">
            <div className="card-body d-flex flex-column" id="card-body4">
              <h5 className="sitecard-title" id="card-title">AGILE TRANSFORMATION/ MODERNIZATION</h5>
              <p className="card-text" id="card-text" data-testid="transfCardText">
                Subject matter experts train and guide your team with ceremonies and launching Agile trains.</p>
              <Link to="/transformation" className="introCardButtonWhiteText mt-auto">
                <button className="btn btn-outline-light button"
                  aria-label='Navigate to Agile Transformation and Modernization'
                >START TRANSFORMING TODAY</button>
              </Link>
            </div>
          </div>
          <div className="card" id="introCard">
            <div className="card-body d-flex flex-column" id="card-body3">
              <h5 className="sitecard-title" id="card-title">NO CODE/LOW CODE SOLUTIONS</h5>
              <p className="card-text" id="card-text" data-testid="healthCardText">Migration of traditional applications and processes to platforms like ServiceNow and SalesForce.</p>
              <Link to="/low-code" className="introCardButtonWhiteText mt-auto">
                <button className="btn btn-outline-light button"
                  aria-label='Navigate to No Code Low Code Solutions'
                >START CREATING TODAY</button>
              </Link>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}
export default IntroCards;
