/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function CourseTest() {
  const [allCourses, setAllCourses] = useState([]);
  const coursesArray = Object.values(allCourses);
  const [loading, setLoading] = useState(true);

  const getCourseList = async () => {
    try {
      const response = await fetch(
        'https://pp1chtzivl.execute-api.us-east-1.amazonaws.com/v1/partner/courses/partnersListedCourses/1'
        // 'https://eykbj2uxie.execute-api.us-east-1.amazonaws.com/v1/partner/courses/partnersListedCourses/1'
        // 'http://localhost:8080/courses/api/get/'
      );
      const jsonData = await response.json();
      return jsonData;
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    const fetchCourseList = async () => {
      const courseList = await getCourseList();
      setAllCourses(courseList);
    };

    fetchCourseList();
  }, [allCourses]);

  // useEffect(() => {
  //   console.log('useEffect is running');
  //   const getCourse = async () => {
  //     try {
  //       const response = await fetch(
  //         // -------Staging endpoint-------
  //         // -------Prod endpoint--------
  //         // 'https://eykbj2uxie.execute-api.us-east-1.amazonaws.com/v1/partner/courses/partnersListedCourses/1'
  //       );
  //       const jsonData = await response.json();
  //       console.log(jsonData);
  //       setAllcourses(jsonData);
  //       setLoading(false);
  //       // return jsonData;
  //     } catch (err) {
  //       console.error(err.message);
  //     }
  //   };
  //   getCourse();
  // }, []);

  console.log(allCourses);

  const formatDate = dateString => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
    return formattedDate;
  };

  return (
    <div className="container-fluid">
      <div className='row'>
        {coursesArray.length > 0 ? (
          coursesArray[0].map((course, id) =>
            <div className='col-md-3 col-sm-6 col-xs-12 pb-4' key={id}>
              <div className="card">
                <img
                  src={course.imgURL} />

              </div>
              <div className='card-body'>
                <div className='h2'>{course.name}</div>
                <div className='h5'>{formatDate(course.start_date)} - {formatDate(course.end_date)}</div>
                <div className='h5'>{course.start_time} - {course.end_time}</div>
                <div className='h6'>{course.location}</div>
                <div className='h1'>${course.price}.00</div>
                <div className='card-footer font-weight-bold'>
                  <Link to={'/testOrder' + course.id}>
                    <span
                      className='btn btn-green font-weight-bold'
                      // onClick={() => {
                      //   ReactGA4.event({
                      //     category: 'Course Details',
                      //     action: 'Button Click',
                      //     label: course.courseTitle,
                      //     value: course.id
                      //   });
                      // }}
                    >
                      ENROLL NOW &gt;&gt;
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          )) : (
          <div>Loading courses...</div>
        )}
      </div>
    </div>
  );
}

export default CourseTest;
