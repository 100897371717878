/* eslint-disable max-len */
import React, { useEffect } from 'react';
import Menubar from '../components/Menubar';
import Footer from '../components/Footer';
import updateMetaDescription from '../components/DescriptTag';
import updateMetaKeywords from '../components/KeywordTag';
import DirCpoContactForm from '../components/DirCpoContactForm';

function DirCpo5197() {
  // Update meta description dynamically
  useEffect(() => {
    // eslint-disable-next-line max-len
    updateMetaDescription('Agile training for private, corporate, and government entities. Become a skilled Product Owner, Scrum Master, Release Train Engineer. Embrace agility and unleash your potential!');
  }, []);

  // Update meta keywords dynamically
  useEffect(() => {
    // eslint-disable-next-line max-len
    updateMetaKeywords('Government training, Individual training, Corporate training');
  }, []);

  return (
    <>
      <Menubar />
      <div className='py-lg-20 pt-14 pb-10 bg-cover DirCPO5197HeroImage'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-6 col-lg-7 col-md-8 col-12'>
              <div className='text-white'>
                <h1 className='text-white mb-3 display-4 whiteText-responsive'>
                WEB DEVELOPMENT & MANAGED SERVICES FOR TEXAS DIR-CPO-5197
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid whiteContainer">
        <div className="row pt-3 m-5">
          <div className="col-md-7 col-sm-12">
            <div className="leadTitleTextBold pb-2">
            Web Development &amp;<br/> Managed Services Contract
            </div>
            <div className="dirParagraph">
            We are proud to announce that Appddiction
            Studio has been selected by the Texas Department
            of Information Resources (DIR) to deliver
            Comprehensive Web Development and Managed
            Services as part of the&nbsp;
              <a href="https://dir.texas.gov/cooperative-contracts"
                target="_blank" rel="noreferrer"
              >
              DIR&apos;s Cooperative Contracts Program.
              </a>
            </div>
          </div>
          <div className='col text-center'>
            <img src="./assets/DIR.png"
              alt="DIR-CPO-5197" className="img-fluid"
              width="170" height="84"
            />
          </div>
        </div>
      </div>
      <div className="container pb-5">
        <div className="row pt-3 pb-4">
          <div className="col-12">
            <div className='leadTitleTextBold text-center pt-3 pb-5'>
              Why Choose Appddiction Studio?
            </div>
          </div>
          <div className='col-md-4 col-sm-12'>
            <span className='dirHeadTitles'>
              WEBSITE DESIGN & DEVELOPMENT
            </span><br/>
            <span className='dirCapaText'>
              Our services encompass all aspects of website creation,
          from crafting effective information architecture and
          user-centric UI/UX design to implementing responsive web design,
          HTML5/CSS3 coding, and custom web form development.</span>
          </div>
          <div className='col-md-4 col-sm-12'>
            <span className='dirHeadTitles'>
              WEBSITE HOSTING
            </span><br/>
            <span className='dirCapaText'>
              Benefit from our cloud-based website hosting solution,
            ensuring 99.9% availability, hardware redundancy,
            and unlimited scalability to meet your growing needs.
            </span>
          </div>
          <div className='col-md-4 col-sm-12'>
            <span className='dirHeadTitles'>
            ACCESSIBILITY
            </span><br/>
            <span className='dirCapaText'>
            We conduct comprehensive accessibility tests
            based on recognized standards, including
            Section 508, WCAG 2.0, and TAC 206 and TAC 213,
            to ensure your website meets the highest
            accessibility requirements.
            </span>
          </div>
        </div>
        <div className="row pt-3">
          <div className='col-md-4 col-sm-12'>
            <span className='dirHeadTitles'>
              WEB CONTENT MANAGEMENT
            </span><br/>
            <span className='dirCapaText'>
              Our expertise extends to various Content
              Management Systems such as Drupal and WordPress.
              We provide CMS implementation, configuration,
              template design and implementation,
              content migration, and content workflow
              design and configuration.</span>
          </div>
          <div className='col-md-4 col-sm-12'>
            <span className='dirHeadTitles'>
            MAINTENANCE, TRAINING, &amp; SUPPORT
            </span><br/>
            <span className='dirCapaText'>
            Rely on our team for ongoing website and
            web application maintenance and support,
            backup and restore services, as well as
            comprehensive CMS training to empower you
            to manage your content effectively.
            </span>
          </div>
          <div className='col-md-4 col-sm-12'>
            <span className='dirHeadTitles'>
            TURNKEY SOLUTIONS
            </span><br/>
            <span className='dirCapaText'>
            We offer an all-inclusive turnkey
            solution that seamlessly integrates
            our website design and development
            services, web content management
            services, accessibility services,
            hosting services, and website maintenance
            and support services, providing a
            complete solutions.
            </span>
          </div>
        </div>
      </div>
      <div className="container-fluid whiteContainer">
        <div className='row p-5 m-5'>
          <div className='col-md-4'>
            <div className='leadTitleTextBold pt-3 pb-5'>
              Contract Information
            </div>
          </div>
          <div className='col-md-4'>
            <div className='dirSubTitles'>
              Contract Number
            </div>
            <div>
              <a href="https://dir.texas.gov/contracts/dir-cpo-5197"
                target='_blank' rel='noreferrer'
                className='h2'>
                DIR-CPO-5197
              </a>
            </div>
            <div className='dirSubTitles pt-4'>
              Instructions
            </div>
            <div className='dirCapaText'>
            In order to request any services covered
            by this contract, a customer of DIR must
            have a completed Statement of Work (SOW)
            and an approved Purchase Order (PO).
            The PO should include a reference to
            the DIR Contract Number DIR-CPO-5197.
            </div>
          </div>
          <div className='col-md-4'>
            <div className='dirSubTitles'>
              Warranty
            </div>
            <div className='dirCapaText'>
            Appddiction Studio guarantees a 30-day
            warranty on all provided services.
            During this period, any defects or service
            issues will be promptly resolved at no
            additional cost to the client.
              <div>Refunds are not available.</div>
            </div><br/>
            <div className='dirSubTitles'>
              Pricing
            </div>
            <div className='dirCapaText'>
              <a href="./assets/ADS_564_Pricing_Sheet.xlsx">
                Services pricing can be found here.</a>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className='row p-5 m-5'>
          <div className='col-md-4'>
            <div className='leadTitleTextBold pt-3 pb-5'>
              Vendor<br/>Contact
            </div>
          </div>
          <div className='col-md-4'>
            <div className='dirSubTitles'>
              Vendor Information
            </div>
            <div>
              <a href="https://appddictionstudio.com" className='h2'>
                Appddiction Studio, LLC
              </a>
            </div>
            <div className='dirSubTitles pt-4 pb-2'>
              Vendor Contact
            </div>
            <div className='vendorContact'>
              <a href="mailto:tporter@appddictionstudio.com" className='h4'>
                <span>Tim Porter&nbsp;</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
                  <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
                </svg>
              </a>
              <br/>
              <div className='h5'>
                Phone (210) 859-9677<br/>
                Fax (210) 570-7936
              </div>
            </div>
          </div>
        </div>
      </div>
      <DirCpoContactForm />
      <Footer/>
    </>
  );
}

export default DirCpo5197;
