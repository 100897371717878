import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addStudent } from '../store/orderTotal';

// eslint-disable-next-line no-undef
const { v4: uuidv4 } = require('uuid');

const defaultStudent = {
  firstName: '',
  lastName: '',
  email: '',
  company: '',
  phone: ''
};

function Studentlist() {
  const [student, setStudent] = useState(defaultStudent);
  const students = useSelector(state => state.cart.students);
  console.log(students);
  const dispatch = useDispatch();

  const handleSubmit = event => {
    event.preventDefault();
    dispatch(addStudent(student));
    setStudent(defaultStudent);
  };
  console.log(students);
  // console.log(students.length);

  const numberOfStudents = students.length;
  console.log(numberOfStudents);

  function formatPhoneNumber(inputValue) {
    if (!inputValue) return inputValue;

    const phoneNumber = inputValue.replace(/[^\d]/g, '');

    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }

    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  return (
    <>
      <div className='py-6 py-lg-12'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              {/* <!-- card --> */}
              <div className='card mb-4'>
                {/* <!-- card header --> */}
                <div className='card-header bg-white p-4'>
                  <h4 className='mb-0'>Student Info</h4>
                </div>
                {/* <!-- card body --> */}
                <div className='card-body p-4'>
                  {/* <!-- form --> */}
                  <form className='row' onSubmit={handleSubmit}>
                    <div className='mb-3 col-12 col-lg-6 col-md-12'>
                      <label className='form-label'
                        htmlFor='fname'>* First Name</label>
                      <input type='text' name='firstName' id='firstName'
                        onChange={e => setStudent({ ...student, firstName:
                        e.target.value })}
                        value={student.firstName}
                        required
                        className='form-control'
                        placeholder='First Name Required' />
                    </div>
                    <div className='mb-3 col-12 col-lg-6 col-md-12'>
                      <label className='form-label'
                        htmlFor='lname'>* Last Name</label>
                      <input type='text' name='lastName' id='lastName'
                        onChange={e =>
                          setStudent({ ...student, lastName: e.target.value })}
                        value={student.lastName}
                        className='form-control'
                        placeholder='Last Name Required'
                        required
                      />
                    </div>
                    <div className='mb-3 col-12 col-lg-6 col-md-12'>
                      <label className='form-label'
                        htmlFor='email'>* Email Address</label>
                      <input type='text' name='email' id='email'
                        onChange={e =>
                          setStudent({ ...student, email:
                            e.target.value })}
                        value={student.email}
                        className='form-control'
                        placeholder='Email Address Required'
                        pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'
                        required
                      />
                    </div>
                    <div className='mb-3 col-12 col-lg-6 col-md-12'>
                      <label className=' form-label'
                        htmlFor='phone'>* Phone (xxx-xxx-xxxx)</label>
                      <input name='phone' id='phone'
                        onChange={e =>
                          setStudent({ ...student, phone:
                            formatPhoneNumber(e.target.value) })}
                        value={student.phone}
                        className='form-control'
                        placeholder='Phone Required'
                        required
                        type='tel'
                        pattern='^\d{3}-\d{3}-\d{4}$'/>
                    </div>
                    <div className='mb-3 col-12 col-lg-6 col-md-12'>
                      <label className=' form-label'
                        htmlFor='company'>Company
                      </label>
                      <input type='text' name='company' id='company'
                        onChange={e =>
                          setStudent({ ...student, company:
                            e.target.value })}
                        value={student.company}
                        className='form-control'
                        placeholder='Company'
                      />
                    </div>
                    <div className='col-12 mb-4'>
                      {/* Add Student Button here */}
                      <button className='shadow btn btn-primary
                       mt-3 text-uppercase'
                      type='submit'>
                        add this student
                      </button>
                    </div>
                  </form>
                  {/* Conditonal showing array Students */}
                  {(() => {
                    if (students.length > 0) {
                      return (
                        <div>
                          <div className='card-header bg-white p-4'>
                            <h4 className='mb-0'>Student List
                              [{ numberOfStudents }]</h4>
                          </div>
                          <ul className='mt-3'>
                            {students.map(student => (
                              <li key={uuidv4()}>
                                <p>{student.firstName}&nbsp;
                                  {student.lastName}<br />
                                  {student.email}<br />
                                  {student.phone}<br />
                                  {student.company}
                                </p>
                                <hr />
                              </li>
                            ))}
                          </ul>
                        </div>
                      );
                    }
                  })()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Studentlist;
