import React from 'react';
function Footeraddress() {
  return (
    <>
      <div>
        <div className='mb-4'>
          <img src='./assets/Appddlogo.svg' alt='Appddiction Studio'
            className='footerLogo'width='180' height='79'/><br />
          <span className='phoneNumber text-nowrap'>
            <a href='tel:210-704-7828'>
              210-704-7828
            </a>
          </span>
          <br />
          <span className='addressText'>17211 Jones Maltsberger Rd.<br />
            San Antonio, TX 78247</span>
          <div>
            <img src='./assets/sdvosb.svg'
              alt='Services Disabled Veteran Owned Business'
              className='footerIcon'
              width={60} height={54}/>
            <img src='./assets/sba_8a.svg'
              alt='Small Business Administration 8a'
              className='footerIcon'
              width={60} height={30}
            />
            <img src='./assets/gsa.svg'
              alt='U.S. General Services Administration'
              className='footerIcon'
              width={60} height={60}
            />
            <img src='./assets/gold_spct.svg'
              alt='Scaled Agile Parnter - Gold SPCT'
              className='footerIcon'
              width={60} height={63}
            />
            <img src='./assets/joint_commission.png'
              alt='Joint Commission National Quality Approval'
              className='footerIcon'
              width={60} height={60}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default Footeraddress;
