import React from 'react';

function PrimaryNaicsCodes() {
  return (
    <>
      {/* testing naics codes tabs */}
      <div className="container fluid p-5" id="naicscodes">
        <div className="row">
          <div className="col-sm text-center">
            <div className='h3'>541511</div>
          </div>
          <div className="col-sm text-center">
            <div className='h3'>541512</div>
          </div>
          <div className="col-sm text-center">
            <div className='h3'>541430</div>
          </div>
        </div>
      </div>
    </>
  );
}
export default PrimaryNaicsCodes;
