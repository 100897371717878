import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Announce() {
  // const [thisCourse, setthisCourse] = useState([]);
  const [thisCourse, setThisCourse] = useState(null);

  const getCourse = async () => {
    try {
      const response = await fetch(
        `${window.__ENV__.API_URL}/courses/api/get/closest-future-course`
      );
      const jsonData = await response.json();
      return jsonData;
    } catch (err) {
      console.error(err.message);
    }
  };
  //

  useEffect(() => {
    const fetchCourse = async () => {
      const course = await getCourse();
      setThisCourse(course);
    };

    fetchCourse();
  }, []);

  return (
    <>
      {thisCourse && (
        <div className="alert alert-warning show text-center" role="alert">
          <strong>
            {' '}
            <Link to="/stars3">
              <span className="announceTitle">
                BEST-IN-CLASS 8(a) STARS III GWAC |
              </span>
            </Link>
          </strong>
          <span className="pl-2">
            <Link
              className="btn btn-upcomingCourses text-white bt-sm shadow"
              to={'/order' + thisCourse.id} // Assuming thisCourse.id is correct
            >
              {thisCourse.courseTitle}
              &nbsp;| {thisCourse.startDate} - {thisCourse.endDate} |
              &nbsp;ENROLL NOW&nbsp;
              <i className="fa fa-angle-double-right" aria-hidden="true"></i>
            </Link>
          </span>
        </div>
      )}
    </>
  );
}
export default Announce;
