import React from 'react';

function InstructorCustomers() {
  return (
    <div className='whiteContainer p-5'>
      <div className='row'>
        <div className='col m-3'>
          <div>
            <p className='leadTitleText'>Instructors Are
              Subject Matter Experts
            </p>
            <div data-testid="ARTS-text">
              <p className='leadGrey'>Our instructors have launched
                multiple Agile Release Trains (ARTS)
                functioning in roles as Solution Train
                Engineer, Release Train Engineer,
                Solutions Architect, Scrum Master and Product Owner/
                Product Manager.
              </p>
            </div>
          </div>
        </div>
        <div className='col'>
          <div className='text-center leadDark'>
            <p>CUSTOMERS</p>
            <div className='row'>
              <div className='col'>
                <img src='./assets/faa_brand.svg'
                  className='upcomingCustomerBrands p-1'
                  alt='Federal Aviation Administration' />
              </div>
              <div className='col'>
                <img src='./assets/nasa_brand.svg'
                  className='upcomingCustomerBrands p-1'
                  alt='NASA' />
              </div>
              <div className='col'>
                <img src='./assets/airforce_brand.svg'
                  className='upcomingCustomerBrands p-1'
                  alt='Air Force' />
              </div>
            </div>
            <div className='row pt-3'>
              <div className='col'>
                <img src='./assets/ernst-young_brand.svg'
                  className='upcomingCustomerBrands' alt='Ernst Young' />
              </div>
              <div className='col'>
                <img src='./assets/usda.svg'
                  className='upcomingCustomerBrands pt-3'
                  alt='US Department of Agriculture'
                  width={100}
                  height={84} />
              </div>
              <div className='col'>
                <img src='./assets/cpsenergy_brand.svg'
                  className='cpsCustomerBrands pt-3'
                  alt='CPS Energy'
                  width={180}
                  height={80} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstructorCustomers;
