/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';

function HealthcareBanner() {
  return (
    <div className='container-fluid lightGreyContainer'>
      <div className='row p-5'>
        <div className='col-12 text-center'>
          <div className='row'>
            <div className='col'>
              <div className='display-5'>
            Learn about our Healthcare Staffing services
              </div>
            </div>
          </div>
          <div className='p-3'>
            <img
              src='./assets/jointcommissionlg.webp'
              className='img'
              alt='Appddiction Studio is Joint Commission Certified'
              width={114}
              height={114}
            />
          </div>
          <div className='row justify-content-center'>
            <div className='p-3'>
              <Link to='/healthcare' className='introCardButtonWhiteText'>
                <button className='btn btn-dark btn-lg'>
                  <strong>Explore Healthcare Staffing Services</strong>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HealthcareBanner;
