import React from 'react';
import Footeraddress from './Footeraddress';
import Menulinks from './Menulinks';
import Sba from './Sba';
import Socialmedia from './Socialmedia';

function Footer() {
  return (
    <div className='footer' data-testid='footer'>
      <div className='container'>
        <div className='row p-3'>
          <div className='col'><Footeraddress />
          </div>
          <div className='col'><Menulinks />
          </div>
          <div className='col'><Socialmedia />
          </div>
          <div className='col'><Sba />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footer;
