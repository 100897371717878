import React from 'react';

function NoCourses() {
  return (
    <div className='container p-3'>
      <div className='row p-5'>
        <div className='col'>
          <img src='./assets/safe_scrum_alliance.png'
            width={365}
            height={121}
            alt=''
          />
        </div>
        <div className='col'>
          <div className='leadTitleText'>Looking for Courses?</div>
          <div className='pt-4'>
            <a className='btn btn-lg btn-info'
              href='#contactus'>
              CONTACT US DIRECTLY!
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoCourses;
