import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CreditCardPayment from './CreditCardPayment';
import CareerPayButton from '../components/CareerPayButton';
import {
  updateBillingFirstName,
  updateBillingLastName,
  updateBillingEmail,
  updateBillingPhone,
  updateBillingAddress,
  updateBillingState,
  updateBillingZipCode,
  updateCCNumber,
  updateExpireDate,
  updateCVV
} from '../store/orderTotal';
import { useParams, useHistory } from 'react-router-dom';


function Billing() {
  // const { v4: uuidv4 } = require('uuid');

  const students = useSelector(state => state.cart.students);
  const firstStudent = students[0];
  console.log(firstStudent);
  const [thisCourse, setthisCourse] = useState([]);
  const cart = useSelector(state => state.cart);
  const [billingFirstName, setBillingFirstName] = useState('');

  const [billingLastName, setBillingLastName] = useState('');

  const [billingEmail, setBillingEmail] = useState('');

  const [billingPhone, setBillingPhone] = useState('');

  const [billingAddress, setBillingAddress] = useState('');

  const [billingState, setBillingState] = useState('');

  const [billingZipCode, setBillingZipCode] = useState('');

  const [ccNumber, setccNumber] = useState('');

  const [expireDate, setExpireDate] = useState('');

  const [orderComplete, setOrderComplete] = useState('');

  const [cvv, setCVV] = useState('');
  const history = useHistory();

  const dispatch = useDispatch();
  console.log(cart);

  const handleBillingFirstNameChange = event => {
    event.preventDefault();
    setBillingFirstName(event.target.value);
    dispatch(updateBillingFirstName(event.target.value));
  };

  const handleBillingLastNameChange = event => {
    event.preventDefault();
    setBillingLastName(event.target.value);
    dispatch(updateBillingLastName(event.target.value));
  };

  const handleBillingEmailChange = event => {
    event.preventDefault();
    setBillingEmail(event.target.value);
    dispatch(updateBillingEmail(event.target.value));
  };

  const handleBillingPhoneChange = event => {
    event.preventDefault();
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    setBillingPhone(formattedPhoneNumber);
    dispatch(updateBillingPhone(formattedPhoneNumber));
  };

  const handleBillingAddressChange = event => {
    event.preventDefault();
    setBillingAddress(event.target.value);
    dispatch(updateBillingAddress(event.target.value));
  };

  const handleBillingStateChange = event => {
    event.preventDefault();
    setBillingState(event.target.value);
    dispatch(updateBillingState(event.target.value));
  };

  const handleBillingZipChange = event => {
    event.preventDefault();
    setBillingZipCode(event.target.value);
    dispatch(updateBillingZipCode(event.target.value));
  };

  const handleCCNumberChange = event => {
    event.preventDefault();
    setccNumber(event.target.value);
    dispatch(updateCCNumber(event.target.value));
  };

  const handleExpireDateChange = event => {
    event.preventDefault();
    setExpireDate(event.target.value);
    dispatch(updateExpireDate(event.target.value));
  };

  const handleCVVChange = event => {
    event.preventDefault();
    setCVV(event.target.value);
    dispatch(updateCVV(event.target.value));
  };

  function formatPhoneNumber(inputValue) {
    if (!inputValue) return inputValue;

    const phoneNumber = inputValue.replace(/[^\d]/g, '');

    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }

    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  return (
    <div className='py-6 py-lg-12'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-12'>
            {/* card */}
            <div className='card mb-4'>
              {/* card header */}
              <div className='card-header bg-white p-4'>
                <h4 className='mb-0'>Billing Info</h4>
              </div>
              {/* card body */}
              <div className='card-body p-4'>
                {/* form elements */}

                <div className='row'>
                  <div className='mb-3 col-12 col-lg-6 col-md-12'>
                    <label className='form-label' htmlFor='fname'>
                      * First Name
                    </label>
                    <input
                      type='text'
                      id='billingFirstName'
                      // key={uuidv4()}
                      className='form-control'
                      value={billingFirstName}
                      name='billingFirstName'
                      onChange={handleBillingFirstNameChange}
                      required
                      placeholder='Required'
                    />
                  </div>
                  <div className='mb-3 col-12 col-lg-6 col-md-12'>
                    <label className='form-label' htmlFor='lname'>
                      * Last Name
                    </label>
                    <input
                      type='text'
                      id='billingLastName'
                      className='form-control'
                      value={billingLastName}
                      name='billingLastName'
                      onChange={handleBillingLastNameChange}
                      required
                      placeholder='Required'
                    />
                  </div>
                  <div className='mb-3 col-12 col-lg-6 col-md-12'>
                    <label className='form-label' htmlFor='email'>
                      * Email Address
                    </label>
                    <input
                      type='email'
                      id='billingEmail'
                      className='form-control'
                      value={billingEmail}
                      name='billingEmail'
                      onChange={handleBillingEmailChange}
                      required
                      placeholder='Required'
                    />
                  </div>
                  <div className='mb-3 col-12 col-lg-6 col-md-12'>
                    <label className='form-label' htmlFor='phone'>
                      * Phone (xxx-xxx-xxxx)
                    </label>
                    <input
                      type='tel'
                      id='billingPhone'
                      className='form-control'
                      value={billingPhone}
                      name='billingPhone'
                      onChange={handleBillingPhoneChange}
                      pattern='[0-9]{3}-[0-9]{3}-[0-9]{4}'
                      // pattern='^\d{3}-\d{3}-\d{4}$'
                      required
                      placeholder='Required'
                    />
                  </div>
                  <div className='mb-3 col-12 col-lg-6 col-md-12'>
                    <label className='form-label' htmlFor='address'>
                      Billing Address
                    </label>
                    <input
                      type='text'
                      id='billingAddress'
                      className='form-control'
                      value={billingAddress}
                      name='billingAddress'
                      onChange={handleBillingAddressChange}
                      placeholder='Required'
                    />
                  </div>
                  <div className='mb-3 col-12 col-lg-6 col-md-12'>
                    <label className='form-label' htmlFor='address'>
                      * Billing State (ex: TX, CA, FL)
                    </label>
                    <input
                      type='text'
                      id='billingState'
                      className='form-control'
                      value={billingState}
                      name='billingState'
                      onChange={handleBillingStateChange}
                      required
                      placeholder='Required'
                      maxLength='2'
                    />
                  </div>
                  <div className='mb-3 col-12 col-lg-4 col-md-12'>
                    <label className='form-label' htmlFor='zipcode'>
                      * Billing Zip Code
                    </label>
                    <input
                      type='text'
                      id='billingZipCode'
                      name='billingZipCode'
                      className='form-control'
                      placeholder='Required'
                      maxLength='5'
                      value={billingZipCode}
                      onChange={handleBillingZipChange}
                      required
                    />
                  </div>

                </div>
                <div className='row'>
                  <div className='ml-4 mt-3 mb-2'>
                    <h4>Select Payment Method</h4>
                  </div>
                  <ul
                    className='nav nav-tabs col-12 ml-4'
                    id='myTab'
                    role='tablist'
                  >
                    <li className='nav-item' role='presentation'>
                      <button
                        className='nav-link active'
                        id='creditCard-tab'
                        data-toggle='tab'
                        data-target='#creditCard'
                        type='button'
                        role='tab'
                        aria-controls='creditCard'
                        aria-selected='true'
                      >
                        Credit Card
                      </button>
                    </li>
                    <li className='nav-item' role='presentation'>
                      <button
                        className='nav-link'
                        id='careerPay-tab'
                        data-toggle='tab'
                        data-target='#careerPay'
                        type='button'
                        role='tab'
                        aria-controls='careerPay'
                        aria-selected='false'
                      >
                        CareerPay
                      </button>
                    </li>
                  </ul>
                  <div className='tab-content col-12' id='myTabContent'>
                    <div
                      className='tab-pane fade show active'
                      id='creditCard'
                      role='tabpanel'
                      aria-labelledby='creditCard-tab'
                    >
                      <CreditCardPayment
                        ccNumber={ccNumber}
                        handleCCNumberChange={handleCCNumberChange}
                        expireDate={expireDate}
                        handleExpireDateChange={handleExpireDateChange}
                        cvv={cvv}
                        handleCVVChange={handleCVVChange}
                      />
                    </div>
                    <div
                      className='tab-pane fade'
                      id='careerPay'
                      role='tabpanel'
                      aria-labelledby='careerPay-tab'
                    >
                      <div className='h4 m-3 text-center'>
                        Coming Soon...
                        <br />
                        <img
                          src='./assets/careerpay_logo.svg'
                          className='img-fluid p-3'
                          alt='CareerPay'
                          width={400}
                        />
                        <img
                          src='./assets/learnnow_paylater.png'
                          className='img-fluid p-3'
                          alt='Learn Now Pay Later'
                          width={400}
                        />
                        <br />
                        <a
                          href='https://www.careerpay.com/'
                          target='_blank'
                          rel='noreferrer'
                          className='btn btn-info mt-3
                          font-weight-bold text-uppercase'
                        >
                          Learn More About CareerPay
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
// export default Billing;

Billing.propTypes = {
  students: PropTypes.object
};

export default connect(state => ({
  students: state.students
}))(Billing);
