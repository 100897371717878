import React from 'react';
import Card from 'react-bootstrap/Card';
import Footer from '../components/Footer';
import Menubar from '../components/Menubar';
import LeadershipCard from '../components/LeadershipCard';
import { Link } from 'react-router-dom';

function Leadership() {
  return (
    <>
      <Menubar />
      <div className='py-lg-20 pt-14 pb-10 bg-cover
        aboutUsHeroImage d-none d-lg-block d-xl-block'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-6 col-lg-7 col-md-8 col-12'>
              <div className='text-white'>
                <h1 className='text-white mb-3 display-4 whiteText-responsive'>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-6 col-sm-12 pl-0 pr-0'>
            <Link to='/aboutus'
              className='btn btn-secondary-training pt-3'
              data-testid='primary button'>
              <span className='h3'>
                  ABOUT APPDDICTION
              </span>
            </Link>
          </div>
          <div className='col-md-6 col-sm-12 pl-0 pr-0'>
            <Link to='/leadership'
              className='btn btn-primary-training pt-3'
              data-testid='secondary button'>
              <span className='h3'>
                  LEADERSHIP
              </span>
            </Link>
          </div>
        </div>
      </div>
      <LeadershipCard />
      <Footer />
    </>
  );
}
export default Leadership;
