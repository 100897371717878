import React, { useEffect, useState } from 'react';
import '../App.css';
import '../components/Menubar.css';
import Menubar from '../components/Menubar';
import Footer from '../components/Footer';
import Preorder from './Preorder';
import usePageTracking from '../components/PageTracking';

function Order() {
  // eslint-disable-next-line max-len
  const [pageTitle, setPageTitle] = useState('Begin Course Registration');
  useEffect(() => {
    document.title = pageTitle; // Set the page title to the current state value
  }, [pageTitle]);
  usePageTracking();
  return (
    <>
      <Menubar />
      <div className='d-flex'>
        <div className='col'>
          <Preorder />
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Order;
