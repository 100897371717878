import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Menubar from '../components/Menubar';
import Footer from '../components/Footer';
import TransformationContactForm from '../components/TransformationContactForm';
import { Link } from 'react-router-dom';
import usePageTracking from '../components/PageTracking';
import updateMetaDescription from '../components/DescriptTag';
import updateMetaKeywords from '../components/KeywordTag';

function Modernization() {
  // eslint-disable-next-line max-len
  const [pageTitle, setPageTitle] = useState('Application Modernization | Cloud and Infrastructure Modernization | Migration, Consolidation and Closure | Integrate with Existing Systems');
  useEffect(() => {
    document.title = pageTitle; // Set the page title to the current state value
  }, [pageTitle]);
  // Update meta description dynamically
  useEffect(() => {
    // eslint-disable-next-line max-len
    updateMetaDescription('Integration of diverse technologies is crucial for application development. Cloud infrastructure enables efficient and scalable solutions to modernize businesses.');
  }, []);

  // Update meta keywords dynamically
  useEffect(() => {
    // eslint-disable-next-line max-len
    updateMetaKeywords('modernize, integration, application, development, cloud, infrastructure');
  }, []);

  usePageTracking();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Menubar />
      {/* Hero */}
      <div className='py-lg-20 pt-14 pb-10 bg-cover modernizationHeroImage'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-6 col-lg-7 col-md-8 col-12'>
              <div className='blackText'>
                <div className='mb-3 display-4 blackText-responsive'>
                  AGILE TRANSFORMATION/<br />MODERNIZATION
                </div>
                <p className='mb-3 pe-lg-12 pe-0 leadDark'
                >Our experts can guide you
                  into being an organization that exceeds your client&apos;s
                  expectations. You will see an increase in efficiency,
                  predictability, and productivity.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Navigation Buttons */}
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-6 col-sm-12 pl-0 pr-0'>
            <Link to='/transformation'
              className='btn btn-secondary-training pt-3'
              data-testid='btn-classes'>
              <span className='h3'>
                  AGILE TRANSFORMATION
              </span>
            </Link>
          </div>
          <div className='col-md-6 col-sm-12 pl-0 pr-0'>
            <Link to='/modernization'
              className='btn btn-primary-training pt-3'
              data-testid='btn-public'>
              <span className='h3'>
                  MODERNIZATION
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className='p-5'>
        <div className='container-fluid'>
          <div className='row justify-content-center'>
            {/* Cards start here */}
            <Card id='devSecOpsCard'>
              <Card.Img src='./assets/data.jpg' className='transformCardIcons'
                alt='data'/>
              <Card.Body className='d-flex flex-column' >
                <Card.Title>Application Modernization
                </Card.Title>
                <Card.Text>Rehosting, Refactoring, Replatforming.
                    Our experts can help you update and transform
                    your applications utilizing agile practices.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card id='devSecOpsCard'>
              <Card.Img src='./assets/infrastructure.jpg'
                className='transformCardIcons' alt='infrastructure'/>
              <Card.Body className='d-flex flex-column'>
                <Card.Title>
                Cloud &amp; Infrastructure
                </Card.Title>
                <Card.Text data-testid='infrastructure'>
                  We help customers modernize their cloud
                  and on-prem infrastructure, focusing on security,
                  availability, and operational effectiveness.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card id='devSecOpsCard'>
              <Card.Img src='./assets/migration.jpg'
                className='transformCardIcons' alt='migration' />
              <Card.Body className='d-flex flex-column' >
                <Card.Title data-testid='migration'>
                Migration, Consolidation &amp; Closure
                </Card.Title>
                <Card.Text>
                  Migrate, consolidate and close data centers by
                  implementing organizational and communications strategies.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card id='devSecOpsCard'>
              <Card.Img src='./assets/integration.jpg'
                className='transformCardIcons' alt='integration'/>
              <Card.Body className='d-flex flex-column' >
                <Card.Title data-testid='Integration'>
                Integrate with Existing Systems
                </Card.Title>
                <Card.Text >
                  Based on the organizations standards, we apply successful
                  secure system engineering processes. We can customize systems
                  with seamless integration.
                </Card.Text>
              </Card.Body>
            </Card>
            {/* Cards end here */}
          </div>
        </div>
      </div>
      <TransformationContactForm />
      <Footer />
    </>
  );
}
export default Modernization;
