import React from 'react';

function LeadershipCard() {
  return (
    <>
      <div className='row'>
        <div className='col'>
          <div className='h1 m-5 text-center'>MEET OUR&nbsp;
            <span className='font-weight-bold'>LEADERSHIP</span></div>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <div className='h2 pb-4 text-center'>
            30+ Years of Experience in Federal
            Government IT & Healthcare contracting
          </div>
        </div>
      </div>
      <div className='container mb-5 pb-5'>
        <div className='row text-center'>
          <div className='col-sm-12 col-md-4 pt-4'>
            <div className="card h-100">
              <div className='col pt-3'>
                <img src="./assets/tim_porter.png"
                  className="card-img-top img-fluid"
                  alt="Tim Porter CEO"
                  data-testid="tim porter"
                  width={300}
                  height={300}
                />
              </div>
              <div className="card-body">
                <div className="card-title h5">TIM PORTER</div>
                <p className="card-text">FOUNDER &amp; CEO</p>
              </div>
            </div>
          </div>
          <div className='col-sm-12 col-md-4 pt-4'>
            <div className="card">
              <div className='col pt-3'>
                <img src="./assets/anthony_o.png"
                  className="card-img-top img-fluid img"
                  alt="Anthony Ortega CIO"
                  data-testid="anthony ortega"
                  width={300}
                  height={300}
                />
              </div>

              <div className="card-body">
                <div className="card-title h5">ANTHONY ORTEGA</div>
                <p className="card-text">CHIEF INFORMATION OFFICER</p>
              </div>
            </div>
          </div>
          <div className='col-sm-12 col-md-4 pt-4'>
            <div className="card">
              <div className='col pt-3'>
                <img src="./assets/kevin_s.png"
                  className="card-img-top img-fluid img"
                  alt="Kevin Summersill CTO"
                  data-testid="kevin summersill"
                  width={300}
                  height={300}
                />
              </div>

              <div className="card-body">
                <div className="card-title h5">KEVIN SUMMERSILL</div>
                <p className="card-text">CHIEF TECHNOLOGY OFFICER</p>
              </div>
            </div>
          </div>
        </div>
        <div className='row text-center'>
          <div className='col-sm-12 col-md-4 pt-4'>
            <div className="card">
              <div className='col'>
                <img src="./assets/courtni_g.png"
                  className="card-img-top img-fluid img"
                  alt="Courtni Gaston"
                  data-testid="courtni gaston"
                  width={300}
                  height={300}
                />
              </div>
              <div className="card-body">
                <div className="card-title h5">COURTNI GASTON</div>
                <p className="card-text">OPERATIONS MANAGER</p>
              </div>
            </div>
          </div>
          <div className='col-sm-12 col-md-4 pt-4'>
            <div className='col'>
              <div className="card">
                <img src="./assets/john_g.png"
                  className="card-img-top img-fluid"
                  alt="John Goodwin"
                  data-testid="john goodwin"
                  width={300}
                  height={300}
                />
                <div className="card-body">
                  <div className="card-title h5">JOHN GOODWIN</div>
                  <p className="card-text">VP OF GROWTH</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
}

export default LeadershipCard;
