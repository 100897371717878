import React, { useEffect, useState } from 'react';
import '../App.css';
import '../components/Menubar.css';
import Menubar from '../components/Menubar';
import Footer from '../components/Footer';
import Ordersummary from '../components/Ordersummary';
import Studentlist from '../components/Studentlist';
import usePageTracking from '../components/PageTracking';

function Register() {
  // eslint-disable-next-line max-len
  const [pageTitle, setPageTitle] = useState('Register Students');
  useEffect(() => {
    document.title = pageTitle; // Set the page title to the current state value
  }, [pageTitle]);

  usePageTracking();

  return (
    <>
      <Menubar />
      <div className='pt-5 pt-lg-5 pb-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 col-md-12 col-12'>
              <div className='mb-4'>
                <h4 className='ml-4'>Add Students</h4>
              </div>
              {/* Studentlist section */}
              <Studentlist />
            </div>
            <div className='col-sm-12 col-md-12 col-lg-4'>
              {/* Ordersummary Section */}
              <Ordersummary />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Register;
