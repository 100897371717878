/* eslint-disable max-len */
import { Link } from 'react-router-dom';

function Audit() {
  return (
    <div className="container">
      <div className="row p-5">
        <div className="col text-center">
          <img src="./assets/hcde-logo.svg"
            className='img-fluid'
            width="400"
            height="150" />
        </div>
      </div>
      <div className="row p-5">
        <div className="col text-right">
          <a
            className='btn btn-dark btn-lg m-3'
            href="itms-services://?action=download-manifest&url=https://appddictionstudio.com/install.plist">
            <i className="bi bi-apple p-2"></i>
          iOS App
          </a>
        </div>
        <div className="col">
          <a href="./HCDE_Audit_25_Aug_2023.apk" download
            className='btn btn-dark btn-lg m-3'
          >
            <i className="bi bi-android2 p-2"></i>
          Android App
          </a>
        </div>
      </div>
    </div>
    // <>
    //   <div className="d-flex justify-content-center">
    //     <img src="./assets/hcde-logo.svg"
    //       className='logo-img img-fluid' />
    //   </div>
    //   <div className="row">
    //     <div className='col-md-2'></div>
    //     <div className="col-md-4 col-12 d-flex justify-content-center mt-5">
    //       {/* iOS App Download Link */}
    //       <a target="_blank" href="itms-services://?action=download-manifest&amp;url=./install.plist">
    //         <img src='./assets/app_store.png' className='apple-badge' />
    //       </a>
    //     </div>
    //     <div className="col-md-4 col-12 d-flex justify-content-center mt-4">
    //       {/* Android App Download Link */}
    //       <a href="./HCDE_05_Aug_2023.apk" download>
    //         <img src='./assets/google-play-badge.png' className='android-badge' />
    //       </a>
    //     </div>
    //     <div className="col-md-2"></div>
    //   </div>
    // </>
  );
}

export default Audit;
