import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import Menubar from '../components/Menubar';
import DevsecopsContactForm from '../components/DevsecopsContactForm';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import usePageTracking from '../components/PageTracking';
import updateMetaDescription from '../components/DescriptTag';
import updateMetaKeywords from '../components/KeywordTag';

function DevSecOps() {
  // Update Title dynamically
  // eslint-disable-next-line max-len
  const [pageTitle, setPageTitle] = useState('DevSecOps, Kubernetes, Infrastructure as Code, Continuous Integration, Serverless Architectures, Cloud Infrastructure, Appddiction Studio');
  useEffect(() => {
    document.title = pageTitle; // Set the page title to the current state value
  }, [pageTitle]);

  // Update meta description dynamically
  useEffect(() => {
    // eslint-disable-next-line max-len
    updateMetaDescription('Amid the ever-evolving landscape of cyber threats, security remains paramount. From robust applications to advanced software, cyber protection is crucial. Embracing the power of the cloud enhances cybersecurity defenses, safeguarding data and networks');
  }, []);

  // Update meta keywords dynamically
  useEffect(() => {
    // eslint-disable-next-line max-len
    updateMetaKeywords('Kubernetes, cyber, security, applications, software, cyber, cloud, cybersecurity, data, networks, continuous integration, continuous deployment, serverless architectures, cloud infrastructure');
  }, []);

  usePageTracking();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
      <Menubar />
      {/* Hero */}
      <div className='py-lg-20 pt-14 pb-10 bg-cover devSecOpsHeroImage'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-6 col-lg-7 col-md-8 col-12'>
              <div className='text-white'>
                <h1 className='text-white mb-3 display-4 whiteText-responsive'>
                  DEVSECOPS/<br/>SOFTWARE DEVELOPMENT
                </h1>
                <p className='mb-3 pe-lg-12 pe-0 lead'>
                Our experts provide services in delivering
                working software using advanced technologies &amp;
                platforms.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Navigation Buttons */}
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-6 col-sm-12 pl-0 pr-0'>
            <Link to='/devsecops' className='btn btn-primary-training pt-3'
              data-testid='btn-classes'>
              <span className='h3'>
                  DEVSECOPS
              </span>
            </Link>
          </div>
          <div className='col-md-6 col-sm-12 pl-0 pr-0'>
            <Link to='/softdev' className='btn btn-secondary-training pt-3'
              data-testid='btn-public'>
              <span className='h3'>
                  SOFTWARE DEVELOPMENT
              </span>
            </Link>
          </div>
        </div>
      </div>

      {/* Page Content - Cards */}
      <div className='p-5'>
        <div className='container-fluid'>
          <div className='row justify-content-center'>
            {/* Cards start here */}
            <Card id='devSecOpsCard'>
              <Card.Img src='./assets/kubernetes.png' className='cardBrands'
                alt='kubernetes'/>
              <Card.Body className='d-flex flex-column' >
                <Card.Title data-testid='kubernetes'>KUBERNETES CERTIFIED
                </Card.Title>
                <Card.Text>Automate computer<br/>application deployment
                with<br/>confidence by migrating to<br/>Kubernetes.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card id='devSecOpsCard'>
              <Card.Img src='./assets/infrastructure_as_code.png' height='150px'
                className='cardImages' alt='infrastructure as code'/>
              <Card.Body className='d-flex flex-column'>
                <Card.Title data-testid='infrastructureascode'>
                INFRASTRUCTURE-AS-CODE &amp; CONFIGURATION-AS-CODE
                </Card.Title>
                <Card.Text>Our services provide you the ease
                  in managing all your servers and configuration. Tools
                  like Terraform, Ansible, Puppet, Chef and more, we can
                  transform your infrastructure through automation and
                  continuous delivery.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card id='devSecOpsCard'>
              <Card.Img src='./assets/continuous_integration.jpg'
                className='cardImages' alt='continous integration'/>
              <Card.Body className='d-flex flex-column'>
                <Card.Title data-testid='continuousintegration'>
                CONTINUOUS INTEGRATION/<br/>CONTINUOUS  DEPLOYMENT
                </Card.Title>
                <Card.Text>Ships software quickly and efficiently, getting
                products to market faster than ever before by using
                Continuous Integration / Continous Deployment.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card id='devSecOpsCard'>
              <Card.Img src='./assets/serverless_architectures.jpg'
                className='cardImages' alt='serverless architectures'/>
              <Card.Body className='d-flex flex-column'>
                <Card.Title data-testid='serverlessarchitectures'>
                SERVERLESS ARCHITECTURES
                </Card.Title>
                <Card.Text>Build and run applications and<br/>
                services without having to <br/>manage infrastructure
                </Card.Text>
              </Card.Body>
            </Card>
            <Card id='devSecOpsCard'>
              <Card.Img src='./assets/cloud_infrastructure.jpg'
                className='cardImages' alt='cloud infrastructure'/>
              <Card.Body className='d-flex flex-column'>
                <Card.Title data-testid='cloudinfrastructure'>
                CLOUD, ON-PREM, &amp; HYBRID INFRASTRUCTURE
                </Card.Title>
                <Card.Text >With strong capabilities in on-
                prem, cloud and hybrid infrastructure, we are here to
                help your organization.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card id='devSecOpsCard'>
              <Card.Img src='./assets/cyber.png' className='cardImages'
                alt='cyber'/>
              <Card.Body className='d-flex flex-column' >
                <Card.Title data-testid='cyber'>
                  CYBER
                </Card.Title>
                <Card.Text>Protect your data and your reputation
                  with state-of-the-art cyber solutions to proactively
                  defend your organization. We focus on reducing,
                  securing, and maintaining valuable assets.
                </Card.Text>
              </Card.Body>
            </Card>
            {/* Cards end here */}
          </div>
        </div>
      </div>
      {/* DevSecOpsContactForm */}
      <DevsecopsContactForm />
      {/* Footer */}
      <Footer />
    </>
  );
}
export default DevSecOps;
