/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import usePageTracking from './components/PageTracking';

// Routes
import AboutUs from './Pages/AboutUs';
import Careers from './Pages/Careers';
import Devsecops from './Pages/Devsecops';
import Healthcare from './Pages/Healthcare';
import Home from './Pages/Home';
import Leadership from './Pages/Leadership';
import Media from './Pages/Media';
import MVH from './Pages/MVH';
import Partners from './Pages/Partners';
import SoftDev from './Pages/SoftDev';
import Training from './Pages/Training';
import Transformation from './Pages/Transformation';
import ContractVehicles from './Pages/ContractVehicles';
import Upcoming from './Pages/Upcoming';
import dirDetails from './Pages/Dirdetails';
import Modernization from './Pages/Modernization';
import Order from './Pages/Order';
import Register from './Pages/Register';
import Payment from './Pages/Payment';
import Authorize from './Pages/Authorize';
import Stars3 from './Pages/Stars3';
import DirCpo5197 from './Pages/DirCpo5197';
import LowCode from './Pages/LowCode';
import CourseTest from './components/CareerPayTest/CourseTest';
import NotFound from './Pages/NotFound';
import Audit from './Pages/Audit';
import OrderTest from './components/CareerPayTest/OrderTest';
import RegisterTest from './components/CareerPayTest/RegisterTest';
import CareerPayPayment from './Pages/CareerPayPayment';
import CPInformationPage from './Pages/CPInformationPage';

const HCDERedirect = () => {
  useEffect(() => {
    window.open('//hcde-forms.appddictionstudio.com', '_self', 'noopener noreferrer');
  }, []);

  return null;
};

const HCDEAdminRedirect = () => {
  useEffect(() => {
    window.open('//hcde-forms.appddictionstudio.com/HCDE/admin', '_self', 'noopener noreferrer');
  }, []);

  return null;
};

function App() {
  usePageTracking();
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/devsecops" exact component={Devsecops} />
        <Route path="/training" exact component={Training} />
        <Route path="/healthcare" exact component={Healthcare} />
        <Route path="/mvh" exact component={MVH} />
        <Route path="/leadership" exact component={Leadership} />
        <Route path="/partners" exact component={Partners} />
        <Route path="/careers" exact component={Careers} />
        <Route path="/media" exact component={Media} />
        <Route path="/transformation" exact component={Transformation} />
        <Route path="/softDev" exact component={SoftDev} />
        <Route path="/contractvehicles" exact component={ContractVehicles} />
        <Route path="/upcoming" exact component={Upcoming} />
        <Route path="/dirdetails" exact component={dirDetails} />
        <Route path="/modernization" exact component={Modernization} />
        <Route path="/order:id" exact component={Order} />
        <Route path="/register:id" exact component={Register} />
        <Route path="/payment:id" exact component={Payment} />
        <Route path="/cppayment:id" exact component={CareerPayPayment} />
        <Route path="/authorize" exact component={Authorize} />
        <Route path="/stars3" exact component={Stars3} />
        <Route path="/dir-cpo-5197" exact component={DirCpo5197} />
        <Route path="/low-code" exact component={LowCode} />
        <Route path='/course-test' exact component={CourseTest} />
        <Route path='/testOrder:id' exact component={OrderTest} />
        <Route path='/testRegister:id' exact component={RegisterTest} />
        <Route exact path="/hcde" component={HCDERedirect} />
        <Route exact path="/HCDE/admin" component={HCDEAdminRedirect} />
        <Route path='/HCDE_Audit' component={Audit} />
        <Route path='/careerpay-information' component={CPInformationPage} />
        <Route path="/aboutus" exact component={AboutUs} />
        <Route path="/leadership" exact component={Leadership}/>
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
