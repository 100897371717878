import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Menubar from '../components/Menubar';
import Partnerships from '../components/Partnerships';
import TrainingContactForm from '../components/TrainingContactForm';
import TrainingCustomers from '../components/Transformcustomers';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import usePageTracking from '../components/PageTracking';
import updateMetaDescription from '../components/DescriptTag';
import updateMetaKeywords from '../components/KeywordTag';

function Training() {
  // eslint-disable-next-line max-len
  const [pageTitle, setPageTitle] = useState('Government, Individual, and Corporate Training');
  useEffect(() => {
    document.title = pageTitle; // Set the page title to the current state value
  }, [pageTitle]);

  // Update meta description dynamically
  useEffect(() => {
    // eslint-disable-next-line max-len
    updateMetaDescription('Agile training for private, corporate, and government entities. Become a skilled Product Owner, Scrum Master, Release Train Engineer. Embrace agility and unleash your potential!');
  }, []);

  // Update meta keywords dynamically
  useEffect(() => {
    // eslint-disable-next-line max-len
    updateMetaKeywords('Government training, Individual training, Corporate training');
  }, []);

  usePageTracking();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Menubar />
      {/* Hero */}
      <div className='py-lg-20 pt-14 pb-10 bg-cover trainingHeroImage'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-6 col-lg-7 col-md-8 col-12'>
              <div className='text-white'>
                <h1 className='text-white mb-3
                  display-4 whiteText-responsive'
                data-testid='lead_title'
                >
                  AGILE &amp; DEVSECOPS TRAINING
                </h1>
                <p className='mb-3 pe-lg-12 pe-0 lead'
                  data-testid='lead_para'>
                  Foster an Agile mindset by learning Agile principles and
                  methods to lead self-managed cross-collaborative teams to
                  achieve business goals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Buttons */}
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-6 col-sm-12 pl-0 pr-0'>
            <Link to='/upcoming' className='btn btn-secondary-training pt-3'
              data-testid='btn-classes'>
              <span className='h3'>
                  UPCOMING COURSES
              </span>
            </Link>
          </div>
          <div className='col-md-6 col-sm-12 pl-0 pr-0'>
            <Link to='/training' className='btn btn-primary-training pt-3'
              data-testid='btn-public'>
              <span className='h3'>
                  PUBLIC/PRIVATE TRAINING
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row justify-content-center pt-5'>
          <Card id='trainingCard' data-testid='govCard'>
            <Card.Img
              src='./assets/samuel-schroth-unsplash.png'
              className='cardBrands'
              alt='Government Building'
              data-testid='govImg'
            />
            <Card.Body className='d-flex flex-column' id='trainingCard-body'>
              <Card.Title id='trainingCard-title'>GOVERNMENT</Card.Title>
              <Card.Text id='trainingCard-text' data-testid='govCardText'>
                  GSA Schedule courses and custom courses created to
                  assure agencies meet their mission.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card id='trainingCard'
            data-testid='indvCard'
          >
            <Card.Img
              src='./assets/chris-montgomery-unsplash.png'
              className='cardBrands'
              data-testid='indvImg'
            />
            <Card.Body className='d-flex flex-column'
              id='trainingCard-body2'
            >
              <Card.Title id='trainingCard-title'>INDIVIDUAL</Card.Title>
              <Card.Text id='trainingCard-text' data-testid='indvText'>
                Increase your knowledge base and propel your career
                objectives with tangible certified courses.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card id='trainingCard' data-testid='corpCard'>
            <Card.Img
              src='./assets/jeshoots-unsplash.png'
              className='cardBrands'
              alt='Corporate building'
              data-testid='corpImg'
            />
            <Card.Body className='d-flex flex-column' id='trainingCard-body3'>
              <Card.Title id='trainingCard-title'>CORPORATE
              </Card.Title>
              <Card.Text id='trainingCard-text'
                data-testid='corpCardText'>
                Adapt and thrive in a constantly shifting market, build
                cross-team collaboration, reduce risks and improve
                communication across your organization.
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className='pt-3 pb-5 mb-5'>
        <Partnerships />
      </div>
      <TrainingCustomers />
      <TrainingContactForm />
      <Footer />
    </>
  );
}
export default Training;
