/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import Menubar from '../components/Menubar';
import InstructorCustomers from '../components/InstructorCustomers';
import Upcomingbadges from '../components/Upcomingbadges';
import Upcomingcourses from '../components/Upcomingcourses';
import CourseTest from '../components/CareerPayTest/CourseTest';
import Partnerships from '../components/Partnerships';
import Notlisted from '../components/Notlisted';
import Coursecatalog from '../components/Coursecatalog';
import Companywidetraining from '../components/Companywidetraining';
import TrainingContactForm from '../components/TrainingContactForm';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import usePageTracking from '../components/PageTracking';
import updateMetaDescription from '../components/DescriptTag';
import updateMetaKeywords from '../components/KeywordTag';
import InstructorBio from '../components/InstructorBio';
import NoCourses from '../components/NoCourses';

function Upcoming() {
  // eslint-disable-next-line max-len
  const [pageTitle, setPageTitle] = useState('Agile and DevSecOps Training, SAFe Training, ICAgile, Scrum Alliance, DevOps Institute, Kanban University');
  useEffect(() => {
    document.title = pageTitle; // Set the page title to the current state value
  }, [pageTitle]);

  // Update meta description dynamically
  // Update meta keywords dynamically
  useEffect(() => {
    updateMetaDescription('Agile training for Release Train Engineer and Agile Release Trains, led by experienced subject matter experts. Master Scrum, become a skilled POPM, and excel in both local and virtual courses. Embrace agility and unleash your potential!');
    updateMetaKeywords('agile, training, RTE, ART, STE, architect, scrum, POPM, courses, local, virtual, Scaled Agile Training, ICAgile Training, scrum alliance, devops institute, kanban university, agile training, devsecops training, safe training, icagile training, scrum alliance training, devops institute training, kanban university training');
  }, []);

  useEffect(() => {
  }, []);

  usePageTracking();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Menubar />
      <div>
        <div className='py-lg-20 pt-14 pb-10 bg-cover trainingHeroImage'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-6 col-lg-7 col-md-8 col-12'>
                <div className='text-white'>
                  <h1 className='text-white mb-3 display-4
                    whiteText-responsive' data-testid='lead_title'>
                    AGILE &amp; DEVSECOPS TRAINING
                  </h1>
                  <div data-testid= 'upcomingText'>
                    <p className='mb-3 pe-lg-12 pe-0 lead'
                      data-testid='lead_para'>
                    Foster an Agile mindset by learning Agile principles and
                    methods to lead self-managed cross-collaborative teams to
                    achieve business goals.
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-6 col-sm-12 pl-0 pr-0'>
            <Link to='/upcoming' className='btn btn-primary-training pt-3'
              data-testid='btn-classes'>
              <span className='h3'>
                  UPCOMING COURSES
              </span>
            </Link>
          </div>
          <div className='col-md-6 col-sm-12 pl-0 pr-0'>
            <Link to='/training' className='btn btn-secondary-training pt-3'
              data-testid='btn-public'>
              <span className='h3'>
                  PUBLIC/PRIVATE TRAINING
              </span>
            </Link>
          </div>
        </div>
      </div>
      <Upcomingbadges />
      <InstructorBio />
      {/* <Upcomingcourses /> */}
      <NoCourses />
      <InstructorCustomers />
      <Partnerships />
      <Notlisted />
      <Coursecatalog />
      <Companywidetraining />
      <TrainingContactForm />
      <Footer />
    </>
  );
}
export default Upcoming;
