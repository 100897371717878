import React from 'react';

function Coursecatalog() {
  return (
    <>
      <div className='pt-5 pl-5 pr-5'>
        <h1 className='text-center'>Course Catalog
        </h1>
      </div>
      <div id='accordion' className='p-5 col-md-8 offset-md-2'>
        <div className='card'>
          <div className='card-header' id='headingOne'>
            <div className='mb-0 h5'>
              <button className='btn btn-link p-0' data-toggle='collapse'
                data-target='#collapseOne' aria-expanded='true'
                aria-controls='collapseOne'>
                SCALED AGILE TRAINING
              </button>
            </div>
          </div>
          <div id='collapseOne' className='collapse show'
            aria-labelledby='headingOne' data-parent='#accordion'>
            <div className='card-body pl-5'>
              <p>Certified SAFe® Advanced Scrum Master (SASM)</p>
              <p>Certified SAFe® Agile Product Manager (APM)</p>
              <p>Certified SAFe® Agile Software Engineer (ASE)</p>
              <p>Certified SAFe® Architect (ARCH)</p>
              <p>Certified SAFe® DevOps Practitioner (SDP)</p>
              <p>Certified SAFe® Government Practitioner (SGP)</p>
              <p>Certified SAFe® Lean Portfolio Manager (LPM)</p>
              <p>Certified SAFe® Product Owner/Product Manager (POPM)</p>
              <p>Certified SAFe® Release Train Engineer (RTE)</p>
              <p>Certified SAFe® Scrum Master (SSM)</p>
              <p>Implementing SAFe® with Certified SAFe® Program
                Consultant (SPC)</p>
              <p>Leading SAFe® with Certified SAFe® Agilist (SA)</p>
              <p>SAFe for Teams with Certified SAFe® Practitioner (SP)</p>
              <p>Scaled Agile Framework (SAFe) Overview</p>
            </div>
          </div>
        </div>
        <div className='card'>
          <div className='card-header' id='headingTwo'>
            <div className='mb-0 h5'>
              <button className='btn btn-link collapsed p-0'
                data-toggle='collapse' data-target='#collapseTwo'
                aria-expanded='false' aria-controls='collapseTwo'>
                ICAGILE TRAINING
              </button>
            </div>
          </div>
          <div id='collapseTwo' className='collapse'
            aria-labelledby='headingTwo' data-parent='#accordion'>
            <div className='card-body pl-5'>
              <p>Agile Fundamentals
                (ICP certification)</p>
              <p>Agile Team Facilitation (ICP-ATF certification)</p>
              <p>Agile Coaching (ICP-ACC certification)</p>
              <p>Agile Programming (ICP-PRG certification)</p>
              <p>Agile Test Automation (ICP-ATA certification)</p>
              <p>Agile Testing (ICP-TST certification)</p>
              <p>Foundations of DevOps (ICP-FDO certification)</p>
              <p>Business Agility Foundations (ICP-BAF certification)</p>
              <p>Leading with Agility (ICP-LEA certification)</p>
              <p>Enterprise Product Ownership (ICP-EPO certification)</p>
              <p>Agile Project and Delivery Management (ICP-APM
                 certification)</p>
              <p>Delivery at Scale (ICP-DAS certification)</p>
            </div>
          </div>
        </div>
        <div className='card'>
          <div className='card-header' id='headingThree'>
            <div className='mb-0 h5'>
              <button className='btn btn-link collapsed p-0'
                data-toggle='collapse' data-target='#collapseThree'
                aria-expanded='false' aria-controls='collapseThree'>
                SCRUM TRAINING
              </button>
            </div>
          </div>
          <div id='collapseThree' className='collapse'
            aria-labelledby='headingThree' data-parent='#accordion'>
            <div className='card-body pl-5'>
              <p>Certified Scrum Product Owner</p>
              <p>Certified Scrum Master</p>
            </div>
          </div>
        </div>
        <div className='card'>
          <div className='card-header' id='headingFour'>
            <div className='mb-0 h5'>
              <button className='btn btn-link collapsed p-0'
                data-toggle='collapse' data-target='#collapseFour'
                aria-expanded='false' aria-controls='collapseFour'>
                DEVSECOPS TRAINING
              </button>
            </div>
          </div>
          <div id='collapseFour' className='collapse'
            aria-labelledby='headingFour' data-parent='#accordion'>
            <div className='card-body pl-5'>
              <p>DevOps Leader</p>
              <p>DevOps Foundation</p>
              <p>DevSecOps Foundation</p>
              <p>Site Reliability Engineering Foundation</p>
              <p>Site Reliability Engineering Practitioner</p>
            </div>
          </div>
        </div>
        <div className='card'>
          <div className='card-header' id='headingFive'>
            <div className='mb-0 h5'>
              <button className='btn btn-link collapsed p-0'
                data-toggle='collapse' data-target='#collapseFive'
                aria-expanded='false' aria-controls='collapseFive'>
                KANBAN TRAINING
              </button>
            </div>
          </div>
          <div id='collapseFive' className='collapse'
            aria-labelledby='headingFive' data-parent='#accordion'>
            <div className='card-body pl-5'>
              <p>Team Kanban Practitioner</p>
              <p>Kanban System Design</p>
              <p>Kanban Systems Improvement</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Coursecatalog;
