import React from 'react';
import { useSelector } from 'react-redux';

function Studentlist() {
  const students = useSelector(state => state.cart.students);
  console.log(students);
  // console.log(students.length);

  const numberOfStudents = students.length;
  console.log(numberOfStudents);
  // eslint-disable-next-line no-undef
  const { v4: uuidv4 } = require('uuid');

  return (
    <>
      {/* Conditonal showing array Students */}
      {(() => {
        if (students.length > 0) {
          return (
            <div className='col mt-1'>
              {/* <!-- card --> */}
              <div className='card mb-4'>
                {/* <!-- card body --> */}
                <div className='card-body'>

                  <div>
                    <div className='card-header bg-white p-4'>
                      <h4 className='mb-0'>Student List
                      [{ numberOfStudents }]</h4>
                    </div>
                    <ul className='mt-3'>
                      {students.map(student => (
                        <li key={uuidv4()}>
                          <p>{student.firstName}&nbsp;
                            {student.lastName}<br />
                            {student.email}<br />
                            {student.phone}<br />
                            {student.company}
                          </p>
                          <hr />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      })()}
    </>
  );
}
export default Studentlist;
