import React from 'react';
import Footer from '../components/Footer';
import Menubar from '../components/Menubar';

function Careers() {
  return (
    <>
      <Menubar />
      <h1>This is Careers</h1>
      <p>this is a test by timothy singletary</p>
      <Footer />
    </>
  );
}
export default Careers;
