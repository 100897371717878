/* eslint-disable max-len */
import React from 'react';
import './Menubar.css';
import { Link } from 'react-router-dom';

function Menubar() {
  const ITCapa = './assets/pdf/AppddictionStudio'+
    'Capabilities_June2024.pdf';
  const healthCapa='./assets/pdf/Healthcare_Capabilities_2021.pdf';
  return (
    <div className=''>
      <nav className="navbar navbar-expand-xl navbar-light bg-light">
        <div className='container-fluid'>
          <Link to="/">
            <img src='./assets/Appddlogo.png'
              className='brandLogo'
              alt='Appddiction Studio'
              width='231' height='70'
            />
          </Link>
          <button className="navbar-toggler"
            type="button" data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle"
                  href="#" role="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  tabIndex={0}
                  aria-label='Corporoate Capabalities Menu'>
                CAPABILITIES
                </a>
                <div className="dropdown-menu">
                  <Link className="dropdown-item" to="/devsecops"
                    aria-label="Navigate to Dev Sec Ops and Software Development"
                  >DevSecOps/Software Development</Link>
                  <Link className="dropdown-item" to="/upcoming"
                    aria-label="Navigate to Dev Sec Ops training"
                  >Agile &amp; DevSecOps Training</Link>
                  <Link className="dropdown-item" to="/transformation"
                    aria-label="Navigate to Agile Transformation and Modernization"
                  >Agile Transformation/Modernization</Link>
                  <Link className="dropdown-item" to="/healthcare"
                    aria-label="Navigate to Healthcare Staffing"
                  >Healthcare Staffing</Link>
                </div>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contractvehicles"
                  aria-label="Navigate to the list of contract vehicles"
                >|&nbsp;&nbsp;CONTRACT VEHICLES</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/upcoming"
                  aria-label="Navigate to the list of upcoming courses"
                >| &nbsp;<span className='text-green'>UPCOMING COURSES</span>
                </Link>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle"
                  href="#" role="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  tabIndex={0}
                  aria-label='About Us Menu'>
                |&nbsp;&nbsp;ABOUT US
                </a>
                <div className="dropdown-menu">
                  <Link className="dropdown-item" to="/aboutus"
                    aria-label="Navigate to the about us section"
                  >About Appddiction</Link>
                  <Link className="dropdown-item" to="/upcoming"
                    aria-label="Navigate to Dev Sec Ops training"
                  ></Link>
                  <Link className="dropdown-item" to="/leadership"
                    aria-label="Navigate to Agile Transformation and Modernization"
                  >Leadership</Link>
                </div>
              </li>
            </ul>
            <div className="dropdown ml-3">
              <button className="btn btn-secondary dropdown-toggle"
                type="button"
                id="capabilitiesButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                aria-label='Download PDFs of Information Technology and Healthcare Capabilities'>
                DOWNLOAD CAPABILITIES
              </button>
              <div className="dropdown-menu" aria-labelledby="capabilitiesButton">
                <a className="dropdown-item" href={ITCapa}>IT Capabilities</a>
                <a className="dropdown-item" href={healthCapa}>Healthcare Capabilities</a>
              </div>
            </div>
            <div className='pl-4 pt-2 pt-md-0 font-weight-bold'>
              <Link to='/healthcare' className='text-reset'>
                <span className='p-2'>
                  <img alt='' src='./assets/health_icon.png'
                    width={20}
                    height={20}
                  />
                </span>HEALTHCARE STAFFING
              </Link>
            </div>
          </div>
        </div>

      </nav>
    </div>

  );
}
export default Menubar;
