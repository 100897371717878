import { useState, useEffect } from 'react';
import usePageTracking from '../components/PageTracking';
import Menubar from '../components/Menubar';
import Footer from '../components/Footer';
import Paymentstudentlist from '../components/Paymentstudentlist';
import Paymentordersummary from '../components/Paymentordersummary';
import Help from '../components/Help';
import CPBilling from '../components/CPBilling';

function CareerPayPayment() {
  const [pageTitle, setPageTitle] = useState('CareerPay Payment Summary');

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  usePageTracking();

  return (
    <>
      <Menubar />
      <div>
        <div className='pt-5 pt-lg-5 pb-5'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-8 col-md-12 col-12'>
                <div className='mb-4'>
                  <h4 className='ml-4'>Payment Summary</h4>
                </div>
                {/* Billling & Student List section */}
                <CPBilling />
                <Paymentstudentlist />
              </div>
              {/* Ordersummary Section */}
              <div className='col-sm-12 col-md-12 col-lg-4'>
                <Paymentordersummary paymentType="careerPay" />
                <div className='pl-3 pt-3'>
                  <Help />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CareerPayPayment;
