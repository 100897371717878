/* eslint-disable max-len */
export async function createCheckoutSession(priceId, stripeId) {
  try {
    const checkoutUrl = 'https://1u1ohrbbvk.execute-api.us-east-1.amazonaws.com/v1/payment/stripe/create-checkout-session';
    const getCustomerObjUrl = `https://1u1ohrbbvk.execute-api.us-east-1.amazonaws.com/v1/payment/getCustomerStripeId`;
    const emailQueryParam = encodeURIComponent(sessionStorage.getItem('username').replace(/['"]+/g, ''));
    const updateUserStripeUrl = `https://o1w0fyt44g.execute-api.us-east-1.amazonaws.com/v1/users/updateStripeIdByEmail?email=${emailQueryParam}`;

    const body = {
      default_price: priceId,
      stripe_id: stripeId,
      custEmail: sessionStorage.getItem('username').replace(/['"]+/g, '')
    };

    const checkoutResponse = await fetch(checkoutUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    if (!checkoutResponse.ok) {
      throw new Error('Failed to create checkout session');
    }

    const checkoutData = await checkoutResponse.json();
    console.log('🚀 ~ file: StripeService.jsx:19 ~ createCheckoutSession ~ res.data.url', checkoutData.url);
    console.log(checkoutData);

    const customerStripeId = checkoutData.customer;
    console.log('🚀 ~ file: StripeService.jsx:23 ~ createCheckoutSession ~ customerStripeId', customerStripeId);
    console.log(customerStripeId);

    const receivedCommsResponse = await fetch(getCustomerObjUrl);
    if (!receivedCommsResponse.ok) {
      throw new Error('Failed to get customer data');
    }

    const receivedComms = await receivedCommsResponse.json();
    console.log('🚀 ~ file: StripeService.jsx:27 ~ createCheckoutSession ~ receivedComms', receivedComms);
    console.log(receivedComms);

    if (stripeId === 'null') {
      const customerData = receivedComms.find(comm => comm.customer_id === customerStripeId);
      console.log('🚀 ~ file: StripeService.jsx:37 ~ createCheckoutSession ~ customerData', customerData);
      console.log('email value', sessionStorage.getItem('username').replace(/['"]+/g, ''));
      const data = { stripe_id: customerData.customer_id };
      const updateResponse = await fetch(updateUserStripeUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (!updateResponse.ok) {
        throw new Error('Failed to update user stripe ID');
      }

      console.log('🚀 ~ file: StripeService.jsx:41 ~ createCheckoutSession ~ update', updateResponse);
    }

    // sessionStorage.setItem('stripeId', updateData.)
    return checkoutData;
  } catch (error) {
    console.error('🚀 ~ file: StripeService.jsx:46 ~ createCheckoutSession ~ error', error);
    return null;
  }
}

