/* eslint-disable max-len */
import React, { useEffect } from 'react';
import Menubar from '../components/Menubar';
import Footer from '../components/Footer';

function LowCode() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Menubar />
      <div className='py-lg-20 pt-14 pb-10 bg-cover LowCodeImage'>
        <div className='row ml-4'>
          <div className='col-sm-12 col-md-6'>
            <div className='text-white'>
              <h1 className='text-white mb-3 display-4 whiteText-responsive'>
                  NO CODE/LOW-CODE
              </h1>
            </div>
            <div>
              <p className='text-nocode text-white'>
                  Discover the benefits of harnessing No-Code/ <br />Low-Code solutions to seamlessly transition and <br />optimize your business operations.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='container-why'>
        <div className='row'>
          <div className='col-sm-12 col-md-7'>
            <p className='text-nocode m-5'>
              The emergence of No-Code/Low-Code platforms has revolutionized the software development landscape, enabling organizations to accelerate the migration process while minimizing the need for extensive coding knowledge.
            </p>
          </div>
          <div className='col-sm-12 col-md-4'>
            <img src="./assets/cloud_computing.png" className='nocode-img img-fluid' alt='Cloud Computing' />
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-sm-12 col-md-6 mt-5'>
            <img src='./assets/why_lowcode.png' className='why-img img-fluid d-none d-md-block' alt='Why Low-Code' />
          </div>
          <div className='col-sm-12 col-md-6 mt-5'>
            <div className='h1'>Why Migrate to <br /> No-Code/Low-Code?</div>
            <div id="accordion">
              <div className="card w-75">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button className="btn btn-link text-primary" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Accelerated Migration
                    </button>
                  </h5>
                </div>
                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                  <div className="card-body bg-light">
                    Low code/no code platforms provide pre-built connectors and templates that expedite the migration process. Developers can leverage these resources to seamlessly integrate existing applications and data into ServiceNow and Salesforce, saving time and effort.
                  </div>
                </div>
              </div>
              <div className="card w-75">
                <div className="card-header" id="headingTwo">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed text-primary" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Reduced Reliance on Coding
                    </button>
                  </h5>
                </div>
                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div className="card-body bg-light">
                    With low code/no code approaches, organizations can significantly reduce their dependency on traditional coding. This empowers citizen developers, subject matter experts, and business users to actively participate in the migration process, driving faster innovation and reducing the burden on IT departments.
                  </div>
                </div>
              </div>
              <div className="card  w-75">
                <div className="card-header" id="headingThree">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed text-primary" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Flexibility and Customization
                    </button>
                  </h5>
                </div>
                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                  <div className="card-body bg-light">
                    Low code/no code platforms offer a high degree of flexibility, allowing organizations to customize applications and processes to suit their specific requirements. This flexibility ensures a tailored solution that aligns perfectly with business needs.
                  </div>
                </div>
              </div>
              <div className="card w-75">
                <div className="card-header" id="headingFour">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed text-primary" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      Enhanced Collaboration
                    </button>
                  </h5>
                </div>
                <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                  <div className="card-body bg-light">
                    By enabling business stakeholders to actively participate in the migration process, low code/no code fosters collaboration between IT and non-technical teams. This collaborative approach leads to better alignment, improved communication, and ultimately, more successful outcomes.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container-migrate'>
        <div className='row'>
          <div className='col-sm-12 col-md-5 mt-5'>
            <div className='text-white m-5 display-2 d-flex justify-content-center whiteText-responsive'>
              START MIGRATING <br /> TODAY!
            </div>
            <div>
              <p className='text-white ml-5 mb-5'></p>
            </div>
          </div>
          <div className='col-sm-12 col-md-6'>
            <form>
              <div className='row mr-4 mt-5 mb-5'>
                <div className='col-6'>
                  <label htmlFor='fname' className='text-white'>First Name</label>
                  <input type="text" id='fname' className='form-control'></input>
                </div>
                <div className='col-6'>
                  <label htmlFor='lname' className='text-white'>Last Name</label>
                  <input type="text" id='lname' className='form-control'></input>
                </div>
                <div className='col-6'>
                  <label htmlFor='email' className='text-white mt-2'>Email Address</label>
                  <input type="email" id='email' className='form-control'></input>
                </div>
                <div className='col-6'>
                  <label htmlFor='state' className='text-white mt-2'>State</label>
                  <input type="text" id='state' className='form-control'></input>
                </div>
                <div className='col-6'>
                  <label htmlFor='company' className='text-white mt-2'>Company</label>
                  <input type="text" id='company' className='form-control'></input>
                </div>
                <div className='col-6'>
                  <label htmlFor='phone' className='text-white mt-2'>Phone</label>
                  <input type="text" id='phone' className='form-control'></input>
                </div>
                <div className='col-12'>
                  <label htmlFor='comment' className='text-white mt-2'>Comment</label>
                  <textarea type="text" id='comment' className='form-control'></textarea>
                </div>
                <div className='col-12 mt-4'>
                  <button className='btn btn-outline-light btn-lg mt-2'>Contact Us</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default LowCode;
