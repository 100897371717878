import React from 'react';
import Card from 'react-bootstrap/Card';
import Footer from '../components/Footer';
import Menubar from '../components/Menubar';

function Partners() {
  return (
    <>
      <Menubar />
      <h1>This is Partners</h1>
      <Footer />
    </>
  );
}
export default Partners;
