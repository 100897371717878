import React, { useEffect, useState } from 'react';
import Menubar from '../components/Menubar';
import Footer from '../components/Footer';
import usePageTracking from '../components/PageTracking';

function dirDetails() {
  // eslint-disable-next-line max-len
  const [pageTitle, setPageTitle] = useState('Texas Department of Information Resources (DIR) | Contract Vehicle');
  useEffect(() => {
    document.title = pageTitle; // Set the page title to the current state value
  }, [pageTitle]);

  usePageTracking();

  return (
    <>
      <Menubar />
      {/* Hero */}
      <div className='py-lg-20 pt-14 pb-10 bg-cover ContractVehiclesHeroImage'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-6 col-lg-7 col-md-8 col-12'>
              <div className='text-white'>
                <h1 className='text-white mb-3 display-4 whiteText-responsive'>
                  CONTRACT VEHICLES
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='text-center'>
        <h1 className='pt-5'>Contract Vehicle</h1>
        <p className='pl-5 pr-5 pb-3'>
        </p>
      </div>
      {/* DIR CARD */}
      <div className='container fluid'>
        <div className='row justify-content-center'>
          <div className='col-12'>
            <div className='card bg-light mb-3 contractVehiclesDIRCard'>
              <div className='card-header text-center'>
                <img src='./assets/dir_lrg.png'
                  alt='Texas Department of Information Resources'
                  className='img-fluid'
                  width={267}
                  height={132}
                />
              </div>
              <div className='card-body'>
                <div className='h4'>
                  <a href='https://dir.texas.gov/contracts/dir-cpo-4561'
                    target='_blank'
                    rel='noreferrer'
                    className='contractVehicleTitleLinks'
                  >
                    DIR-CPO-4561
                  </a>
                </div>
                <p className='card-text' data-testid='contract title'>
                  Information Technology Staff Augmentation Contracts (ITSAC)
                  For Personnel To Support Customer IT Projects
                </p>
                <hr />
                <div className='h4 pb-3' data-testid='title large'>
                  <a href='https://dir.texas.gov/cooperative-contracts/it-staffing-services?id=13'
                    target='_blank'
                    rel='noreferrer'
                    className='contractVehicleTitleLinks'
                  >
                    Information Technology Staff Augmentation
                    Contracts (ITSAC) for personnel to support
                    Customer IT projects
                  </a>
                </div>
                <div className='h5 font-weight-bold'>
                  ITSAC Contract Overview</div>
                <p data-testid='c overview'>
                  Appddiction Studio LLC offers Information Technology
                    Staff Augmentation Contracts (ITSAC) for personnel
                    to support Customer IT projects through this contract,
                    for several category positions, listed and fully
                    described at the bottom of this page. Customers can
                    purchase directly through this DIR contract, by
                    contacting us using the information provided in the
                    Contact Information section.</p>
                <div className='h5 font-weight-bold'>Statement of Work</div>
                <p data-testid='sow'>
                  Appddiction Studio welcomes the opportunity to serve
                    any Texas state agency, unit of local government,
                    institution of higher education, the Electric
                    Reliability Council of Texas, the Lower Colorado
                    River Authority, a private school,a private or
                    independent institution of higher education,
                    a volunteer fire department, a public safety
                    entity, a county hospital, public hospital,
                    or hospital district,the state agencies and
                    political subdivisions of other states, and
                    non-telecommunications IT Commodity products
                    and services, “assistance organizations.”
                    Resellers are not available for this contract.</p>
                <div className='h5 font-weight-bold'>HUB Certification</div>
                <p data-testid='hubcert'>Appddiction Studio is HUB (Historically
                    Underutilized Business) as Minority and Service
                    Disabled Veteran Owned.</p>
                <p>
                  <a href=
                    './assets/pdf/Texas_HUB Certificate_Appddiction Studio.pdf'
                  data-testid='hubcertlink'
                  className='contractVehicleTitleLinks'
                  >
                    Texas HUB Certificate Appddiction Studio</a></p>
                <div className='h5 font-weight-bold'>Obtain a Quote or Place
                  an Order</div>
                <p data-testid='obtaintext'>To obtain quotes, place purchase
                  orders, or for additional information
                  DIR-CPO-4561 must be included on the PO.</p>
                <p>Questions can be directed to Timothy Porter at
                    Appddiction Studio.</p>
                <p>Timothy M. Porter <br />17211 Jones Maltsberger Rd.<br />
                San Antonio, TX 78247-2817<br />
                Phone: 210-859-9677<br />
                Fax: 210-570-7936<br />
                Email: <a href='mailto:tporter@appddictionstudio.com'
                    className='contractVehicleTitleLinks'>
                tporter@appddictionstudio.com</a></p>
                <div className='h5 font-weight-bold'>Subcontractor</div>
                <p>Please see below for the approved Subcontractors
                    for DIR-CPO-4561</p>
                <p>Pedigo Staffing Services <br />
                Debbie Pedigo<br />
                503 S Austin<br />
                Seguin, Texas 78155<br />
                Austin/Seguin Office: 830-433-4604<br />
                San Antonio Office: 210-401-4501<br />
                Email: <a href='mailto:debbiep@pedigostaffing.com'
                    className='contractVehicleTitleLinks'>
                  debbiep@pedigostaffing.com</a></p>
                <div className='h5 font-weight-bold'>
                Refund Policy
                </div>
                <p className='pb-3' data-testid='refundtext'>
                  If Client is not satisfied with
                  any level of work, they must immediately bring it
                  to the notice of the Contractor as any negotiations
                  at a later date will not be entertained. Due to the
                  nature of the provided deliverables, refunds are
                  not available.
                </p>
                <img src='./assets/dir_lrg.png'
                  alt='Texas Department of Information Resources'
                  className='img-fluid'
                  width={267}
                  height={132}/>
                <div className='font-weight-bold pt-4'>CONTRACT NUMBER
                </div>
                <div className='h2 text-monospace'>DIR-CPO-4561
                </div>
                <hr />
                <div className='h3 text-success font-weight-bold'>
                  <i className="bi bi-patch-check-fill pr-2 success-green"></i>
                  <span className='success-green'>E-Rate Qualified</span>
                </div>
                <div className='h4'>What Is E-Rate?</div>
                <p data-testid='erate_answer'>
                  The E-Rate program provides discounts to public K-12
                  schools and public libraries purchasing data transmission
                  services, telecommunications services, Internet access,
                  Internet connections, and basic maintenance of internal
                  connections. Click here to view a list of current
                  eligible services.</p>
                <div className='h4'>Who Can Participate in E-Rate?</div>
                <p data-testid='partc_answer'>
                  Eligible customers include, but are not limited to K-12
                  public schools, school districts, charter schools,
                  and public libraries. Customers should have an approved
                  technology plan and file the proper Schools and
                  Libraries Division forms in a timely manner.</p>
                <div className='h4'>How We Make Using E-Rate Easy</div>
                <p data-testid='proc_process'>
                  We have simplified the procurement process for products
                  and services that qualify for E-Rate funding by filing
                  the Form 470 with USAC on select RFOs. Contracts
                  awarded under these RFOs are considered
                  E-Rate Eligible.</p>
                <hr />
                <div className='h4'>Additional Resources</div>
                <p>
                  <a href='./assets/pdf/DIR-CPO-4561_contract.pdf'
                    className='contractVehicleTitleLinks'>
                    - Appddiction Studio DIR-CPO-4561 Contract
                  </a>
                </p>
                <p>
                  <a href='https://dir.texas.gov/View-About-DIR/Pages/Content.aspx?id=41'
                    target={self}
                    className='contractVehicleTitleLinks'
                  >
                    - Appddiction Studio DIR Cooperative Contracts</a>
                </p>
                <p>
                  <a href='./assets/pdf/DIR-CPO-4561_contract_t_and_c.pdf'
                    className='contractVehicleTitleLinks'
                  >
                    - DIR Standard Terms and Conditions</a>
                </p>
                <p>
                  <a href='./assets/pdf/DIR-CPO-4561_appendixb_hub_subplan.pdf'
                    className='contractVehicleTitleLinks'>
                    - Appddiction Studio DIR Appendix B HUB Subcontracting Plan
                  </a>
                </p>
                <p>
                  <a href=
                    './assets/pdf/DIR-CPO-4561_appendixc_itsac_nte_rates.pdf'
                  className='contractVehicleTitleLinks'
                  >
                    - Appddiction Studio DIR Appendix C ITSAC NotToExceedRates
                  </a>
                </p>
                <p>
                  <a href=
                    './assets/pdf/DIR-CPO-4561_appndxd_itsac_title_descr.pdf'
                  className='contractVehicleTitleLinks'
                  >
                    - Appddiction Studio DIR Appendix D ITSAC Titles and
                    Descriptions
                  </a>
                </p>
                <p>
                  <a href='./assets/pdf/DIR-CPO-TMP-445_rfo_itsac_services.pdf'
                    className='contractVehicleTitleLinks'>
                    - Appddiction Studio DIR RFO ITSAC Services</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default dirDetails;
