import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Menubar from '../components/Menubar';
import Footer from '../components/Footer';
import Transformcustomers from '../components/Transformcustomers';
import TransformationContactForm from '../components/TransformationContactForm';
import { Link } from 'react-router-dom';
import usePageTracking from '../components/PageTracking';
import updateMetaDescription from '../components/DescriptTag';
import updateMetaKeywords from '../components/KeywordTag';

function Modernization() {
  // eslint-disable-next-line max-len
  const [pageTitle, setPageTitle] = useState('Agile Transformation and Modernization | SAFe Gold Partner SPCT Organization');
  useEffect(() => {
    document.title = pageTitle; // Set the page title to the current state value
  }, [pageTitle]);

  // Update meta description dynamically
  useEffect(() => {
    // eslint-disable-next-line max-len
    updateMetaDescription('In the world of tech, a team embraced agile, leading an adoption of transformation. Continuous improvement fueled innovation and effective communication. Strong leadership guided the training, forging success.');
  }, []);

  // Update meta keywords dynamically
  useEffect(() => {
    // eslint-disable-next-line max-len
    updateMetaKeywords('agile, adoption, transformation, team, improvement, innovation, communication, leadership, training');
  }, []);

  usePageTracking();

  return (
    <>
      <Menubar />
      {/* Hero */}
      <div className='py-lg-20 pt-14 pb-10 bg-cover modernizationHeroImage'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-6 col-lg-7 col-md-8 col-12'>
              <div className=''>
                <div className='mb-3 display-4 blackText-responsive'>
                  AGILE TRANSFORMATION/<br />MODERNIZATION
                </div>
                <p className='mb-3 pe-lg-12 pe-0 leadDark'>
                  Our experts can guide you
                  into being an organization that exceeds your client&apos;s
                  expectations. You will see an increase in efficiency,
                  predictability, and productivity.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Navigation Buttons */}
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-6 col-sm-12 pl-0 pr-0'>
            <Link to='/transformation' className='btn btn-primary-training pt-3'
              data-testid='btn-classes'>
              <span className='h3'>
                  AGILE TRANSFORMATION
              </span>
            </Link>
          </div>
          <div className='col-md-6 col-sm-12 pl-0 pr-0'>
            <Link to='/modernization'
              className='btn btn-secondary-training pt-3'
              data-testid='btn-public'>
              <span className='h3'>
                  MODERNIZATION
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className='p-5'>
        <Transformcustomers />
        <div className='container-fluid'>
          <div className=''>
            {/* Cards start here */}
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3
              row-cols-xl-4 g-4 justify-content-center">
              <div className="col pt-4">
                <div className="card h-100">
                  <img src="./assets/gold_spct.jpg"
                    className="card-img-top"
                    alt="Scaled Agile Partner Gold SPCT" />
                  <div className="card-body">
                    <div className="card-title h5">
                      SAFe Gold Partner SPCT Organization</div>
                    <p className="card-text">
                      We are an Agile-Certified
                      organization with a Lean Agile Center of Excellence</p>
                  </div>
                </div>
              </div>
              <div className="col pt-4">
                <div className="card h-100">
                  <img src="./assets/adapt_thrive.jpg"
                    className="card-img-top"
                    alt="" />
                  <div className="card-body">
                    <div className="card-title h5">Adapt & Thrive
                    in a Constantly Shifting Market</div>
                    <p className="card-text">
                    We help cultivate an environment that encourages
                    creativity, flexibility and fluency.</p>
                  </div>

                </div>
              </div>
              <div className="col pt-4">
                <div className="card h-100">
                  <img src="./assets/satisfy_customer.jpg"
                    className="card-img-top"
                    alt="" />
                  <div className="card-body">
                    <div className="card-title h5">Better Communication
                    Equals Customer Satisfaction</div>
                    <p className="card-text">Learn to deliver
                      improved customer satisfaction and
                      customer-focused outcomes.</p>
                  </div>

                </div>
              </div>
              <div className="col pt-4">
                <div className="card h-100">
                  <img src="./assets/strong_leaders.jpg"
                    className="card-img-top"
                    alt="" />
                  <div className="card-body">
                    <div className="card-title h5">Build Stronger Leaders</div>
                    <p className="card-text">Embracing an Agile mindset
                    fosters strong leaders who drive innovation
                    and elevate products and services.</p>
                  </div>

                </div>
              </div>
              <div className="col pt-4">
                <div className="card h-100">
                  <img src="./assets/increase_prod.jpg"
                    className="card-img-top"
                    alt="" />
                  <div className="card-body">
                    <div className="card-title h5">Increase Productivity
                      &amp; Decrease Risks</div>
                    <p className="card-text">Integrate Agile principles to
                    help teams deliver software on a
                    faster cadence and incrementally for
                    quick customer feedback and increased productivity.</p>
                  </div>
                </div>
              </div>
              <div className="col pt-4">
                <div className="card h-100">
                  <img src="./assets/team_culture.jpg"
                    className="card-img-top"
                    alt="" />
                  <div className="card-body">
                    <div className="card-title h5">Improve Team
                    Satisfaction</div>
                    <p className="card-text">Foster a workplace
                    culture built on trust and continuous learning,
                    and you&apos;ll see your teams perform better,
                    have more energy, enjoy improved health,
                    and stick around longer.</p>
                  </div>
                </div>
              </div>
              <div className="col pt-4">
                <div className="card h-100">
                  <img src="./assets/continous_learn.jpg"
                    className="card-img-top"
                    alt="" />
                  <div className="card-body">
                    <div className="card-title h5">Continuous Learning
                    &amp; Improvement
                    </div>
                    <p className="card-text">With the Agile mindset
                    your teams will experience a
                    continuous learning and continuous
                    improvement environment.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TransformationContactForm />
      <Footer />
    </>
  );
}
export default Modernization;
