import React from 'react';
import emailjs from 'emailjs-com';

function sendEmail(e) {
  e.preventDefault();
  emailjs.sendForm('service_u3uzxk8',
    'template_m9rjxel', e.target,
    'WQJaRUUv_toVZSlnN')
    .then(result => {
      console.log(result.text);
      location.reload('/training');
    }, error => {
      console.log(error.text);
    });
}

function TrainingContactForm() {
  return (
    <div className='greenContainer' id='contactus'>
      <div className='row'>
        <div className='col-xl-6 col-lg-7 col-md-8 col-12'>
          <div className='text-white mb-3 display-2
            whiteText-responsive' id='contactForm'
          data-testid='contactForm'>
          CERTIFICATION<br />IS A FEW CLICKS AWAY!
          </div>
          <div className='lead pb-5'>
           Improve your abilities and further your
           career with our courses. You will gain new
           skills like the 100+ students that have
           learned from our experts. Train to develop
           an Agile capacity and mindset so people and
           organizations can thrive in continually
           challenging environments.
          </div>
        </div>
        <div className='col'>
          <form onSubmit={sendEmail}
            className='text-white'>
            <div className='row'>
              <div className='col-md-6 mb-3'>
                <label htmlFor='validationCustom01'>* FIRST NAME</label>
                <input label='first_name' type='text' name='first_name'
                  className='form-control'
                  id='validationCustom01'
                  placeholder='First Name'
                  required />
                <div className='valid-feedback'>
                    Looks good!
                </div>
                <div className='invalid-feedback text-warning'>
                    Please provide a first name.
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <label htmlFor='validationCustom02'>* LAST NAME</label>
                <input type='text' name='last_name' className='form-control'
                  id='validationCustom02'
                  placeholder='Last Name' required />
                <div className='valid-feedback'>
                    Looks good!
                </div>
                <div className='invalid-feedback text-warning'>
                    Please provide a last name.
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6 mb-3'>
                <label htmlFor='validationCustom03'>* EMAIL ADDRESS</label>
                <input type='text' name='email_address' className='form-control'
                  id='validationCustom03'
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  placeholder='name@domain.com'
                  required />
                <div className='valid-feedback'>
                    Looks good!
                </div>
                <div className='invalid-feedback text-warning'>
                    Please provide a valid email address.
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <label htmlFor='validationCustom04'>* PHONE</label>
                <input type='text' name='phone' className='form-control'
                  id='validationCustom04'
                  placeholder='XXX-XXX-XXX'
                  pattern='^\d{3}-\d{3}-\d{4}$'
                  required
                />
                <div className='valid-feedback'>
                    Looks good!
                </div>
                <div className='invalid-feedback text-warning'>
                    Please provide a valid phone.
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6 mb-3'>
                <label htmlFor='validationCustom05'>COMPANY</label>
                <input type='text' name='company' className='form-control'
                  id='validationCustom05'
                  placeholder='Company' />
                <div className='invalid-feedback'>
                    Please provide a valid Company.
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <label htmlFor='validationCustom06'>STATE</label>
                <input type='text' name='state' className='form-control'
                  id='validationCustom06'
                  placeholder='State'/>
              </div>
              <div className='col-md-12 mb-5'>
                <label htmlFor='validationCustom07'>MESSAGE</label>
                <textarea className='form-control' name='comment'
                  id='validationCustom07'
                  placeholder='Leave us a message/comment.' rows='4'></textarea>
              </div>
            </div>
            {/* <ReCAPTCHA
              sitekey='6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'/> */}
            <button className='btn btn-outline-light btn-lg'
              type='submit' value='validate'>CONTACT US</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default TrainingContactForm;
