/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import '../App.css';
import '../components/Menubar.css';
import Menubar from '../components/Menubar';
import Footer from '../components/Footer';
import Paymentordersummary from '../components/Paymentordersummary';
import Billing from '../components/Billing';
import Paymentstudentlist from '../components/Paymentstudentlist';
import Help from '../components/Help';
import usePageTracking from '../components/PageTracking';

function Payment() {
  // eslint-disable-next-line max-len
  const [pageTitle, setPageTitle] = useState('Payment Summary');
  // const [paymentType, setPaymentType] = useState('');
  useEffect(() => {
    document.title = pageTitle; // Set the page title to the current state value
  }, [pageTitle]);

  usePageTracking();

  // const handlePaymentTypeChange = event => {
  //   setPaymentType(event.target.value);
  // };
  // console.log(paymentType);

  return (
    <>
      <Menubar />
      <div>
        <div className='pt-5 pt-lg-5 pb-5'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-8 col-md-12 col-12'>
                <div className='mb-4'>
                  <h4 className='ml-4'>Payment Summary</h4>
                </div>
                {/* Billling & Student List section */}
                <Billing />
                <Paymentstudentlist />
              </div>
              {/* Ordersummary Section */}
              <div className='col-sm-12 col-md-12 col-lg-4'>
                <Paymentordersummary paymentType="credit" />
                <div className='pl-3 pt-3'>
                  <Help />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Payment;
