import React, { useEffect, useState } from 'react';
import '../App.css';
import '../components/Menubar.css';
import Menubar from '../components/Menubar';
import Footer from '../components/Footer';
import Auth from '../components/Auth';
import Help from '../components/Help';
import usePageTracking from '../components/PageTracking';

function Authorize() {
  // eslint-disable-next-line max-len
  const [pageTitle, setPageTitle] = useState('Authorize Payment');
  useEffect(() => {
    document.title = pageTitle; // Set the page title to the current state value
  }, [pageTitle]);

  usePageTracking();

  return (
    <>
      <Menubar />
      <div className='pt-5 pt-lg-5 pb-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-12 col-12'>
              {/* Authorization section */}
              <Auth />
            </div>
            <div className='col-lg-6 col-md-12 col-12
              d-flex justify-content-center'>
              {/* Help component */}
              <Help />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Authorize;
