import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function ApplyToday() {
  return (
    <div className='container'>
      <div className='row pb-5 pt-5'>
        <div className='col-md-6 text-center'>
          <div className='p-4'>
            <img src='./assets/pdf-fill.svg'
              width={175}
              height={258}
              alt=''
            />
          </div>
        </div>
        <div className='col-md-6 text-sm-center'>
          <div className='pt-5 pb-4 display-6-bold'>
          Apply Today!
          </div>
          <div className='pt-3 h5'>
            <a href="./assets/pdf/job_application.pdf"
              target={self}
              className='contractVehicleLinks'
            >
              <span>
                <img src='./assets/icon_download.svg'
                  alt=''
                  width={34}
                  height={46}
                  className='p-2'/>
              </span>
            Download the Appddiction Studio Job Application
            </a>
          </div>
          <div className='pt-3 h5'>
            <a href="./assets/pdf/ari_form.pdf"
              target={self}
              className='contractVehicleLinks'
            >
              <span>
                <img src='./assets/icon_download.svg'
                  alt=''
                  width={34}
                  height={46}
                  className='p-2'/>
              </span>
            Download the Authorization
            of Release of Information Form
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplyToday;
