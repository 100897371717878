import React from 'react';

function Partnerships() {
  return (
    <>
      <div className='mt-5'>
        <div className='pt-3'>
          <h1 className='text-center pb-3'>Partnerships</h1>
          <div className='text-center h5'
            data-testid='partnershipsText'>
            Partners are vital to
            attendee&apos;s training success
            and experience.<br /> These partners are an important part
            of our training in the Education, Agile, and DevSecOps
            industries.
          </div>
        </div>
        <div className='container d-flex justify-content-center p-2'>
          <div className='row col-12'>
            <div className='col text-center pt-4'>
              <img src='./assets/utsa.svg' alt='UTSA'
                className='utsaLogo'
                width={210} height={88}/>
            </div>
            <div className='col text-center mb-3'>
              <img src='./assets/pennsylvania.png' alt='Shippensburg'
                className='pennsylvaniaLogo'
                width={200} height={147} />
            </div>
            <div className='col text-center'>
              <img src='./assets/thomas.svg' alt='Thomas University'
                className='thomasLogo'
                width={220} height={126}/>
            </div>
            <div className='col text-center pt-1'>
              <img src='./assets/ppsc-vertical.png' alt='Pikes Peak'
                className='pikespeakLogo'
                width={200} height={129}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Partnerships;
