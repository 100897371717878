import React from 'react';

function DevSecOpsTools() {
  return (
    <div className='container-fluid whiteContainer'>
      <div className='container'>
        <div className='row pl-4 pr-4 pt-5'>
          <div className='col-12 text-center'>
            <div className='display-5-bold'>
          Our DevSecOps Tools &amp;
          Process
            </div>
          </div>
          <div className='col-12'>
            <div className='text-center'>
              <img alt='Plan, code, build, test, release,
                deploy, operate, and monitor'
              src='./assets/devsecops_tools.webp'
              className='img-fluid'
              width={766}
              height={505}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DevSecOpsTools;
