import React from 'react';

function Companywidetraining() {
  return (
    <div className='darkGreyContainer' testid='1'>
      <div className='row'>
        <div className='col'>
          <h1>Company-Wide Training
          </h1>
          <p className='leadDark'>Looking to book a private training
            for your company?  Submit a request form and we will
            be happy to accommodate your private training needs.
          </p>
          <a className='btn btn-green btn-lg'
            data-testid='infobutton' href='#contactForm'>GET MORE INFO</a>
        </div>
        <div className='col'>
          <img src='./assets/company_wide_training.webp'
            className='companywideTrainingImage img
            d-none d-md-block' alt='Three young adults
            working on their laptops
            at a wooden table'
            width={600} height={400} />
        </div>
      </div>
    </div>
  );
}
export default Companywidetraining;
