import React from 'react';

function Socialmedia() {
  return (
    <>
      <div>
        <div>
          <div className='mb-1 mt-4 h5'>JOIN THE CONVERSATION</div>
          <ul className='list-group'>
            <li className='list-group-item'><a href='https://www.facebook.com/AppddictionStudio'
              className='list-group-item-link'>
              <img src='./assets/facebook-f.svg' alt='Visti our Facebook Page'
                className='fbSocialIcon'
                width={13} height={25} /></a>
            <a href='https://www.instagram.com/appddictionstudio/' className='list-group-item-link'>
              <img src='./assets/instagram.svg' alt='Vist our Instagram'
                className='footerSocialIcon'
                width={20} height={23} /></a>
            <a href='https://www.youtube.com/channel/UC2N1lewYHydznOmQsoHRVVQ'
              className='list-group-item-link'>
              <img src='./assets/youtube.svg' alt='Visit our Youtube channel'
                className='footerSocialIcon'
                width={20} height={18} /></a>
            <a href='https://twitter.com/AppddictionSTU'
              className='list-group-item-link'>
              <img src='./assets/x_com.svg' alt='Visit our X dot com feed'
                className='footerSocialIcon'
                width={20} height={20} /></a>
            <a href='https://www.linkedin.com/company/appddiction-studio/'
              className='list-group-item-link'>
              <img src='./assets/linkedin-in.svg' alt='Vist our Linked In page'
                className='footerSocialIcon'
                width={20} height={20} /></a>
            <a href='mailto:tporter@appddictionstudio.com'
              className='list-group-item-link'>
              <img src='./assets/email.svg' alt='Send an Email'
                className='footerSocialIcon'
                width={20} height={20} /></a>
            </li>
          </ul>
          <img src='./assets/sba_trophy.jpg'
            alt='2020 SBA National Small
            Business Person of the Year' className='sbaTrophy'
            width={250} height={129}
          />
          {/* <div className='mb-3'>
            <input type='email' className='form-control'
              placeholder='name@example.com' />
            <button type='button' className='btn btn-success mt-2'>
            SUBSCRIBE
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Socialmedia;
