import { Card } from 'react-bootstrap';

/* eslint-disable max-len */
function InstructorBio() {
  return (
    <div className='container-fluid container-health p-4'>
      <div className='row'>
        <div className='col'>
          <div className='h2 font-weight-bold text-center'>
              Meet the Instructors
          </div>
        </div>
      </div>
      <div className='container p-3'>
        <div className='row'>
          <div className='col-sm-12 col-md-6'>
            <div className='row'>
              <div className='col text-center'>
                <img src='../assets/tim_porter.webp'
                  className='img-fluid pb-3'
                  width={260}
                  height={260}
                  alt=''
                />
                <div className='pb-3 text-bio text-left'>
                  <span className='h3 font-weight-bold'>TIM PORTER</span>,
                  a medically retired Army veteran of 10 years,
                became the CEO and Founder of Appddiction Studio after winning the
                USA Network&apos;s Character Unite Award in 2011 for developing an
                anti-bullying app for schools. His organization has created over
                200 commercial mobile apps and is a leader in Enterprise transformation,
                focusing on Agile and DevSecOps in government and commercial industries.
                Tim is an SPCT, SAFe Fellow, and experienced Agile Coach, having trained
                thousands of students and launched multiple trains in the government sector.
                He is certified to teach a wide range of SAFe and Agile courses,
                including SAFe Practice Consultant, Leading SAFe, and various Scrum and
                ICAgile courses.
                </div>
              </div>
            </div>
          </div>
          <div className='col'>
            <div className='row'>
              <div className='col text-center'>
                <img src='../assets/anthony_ortega.webp'
                  className='img-fluid pb-3'
                  width={260}
                  height={260}
                  alt=''
                />
                <div className='pb-3 text-bio text-left'><span className='h3 font-weight-bold'>ANTHONY ORTEGA</span>, is an instructor for Appddiction Studio with over 17 years of experience architecting,
                    automating, designing, and implementing enterprise applications across multiple industries for large
                    organizations, small companies, non-profits, and government organizations. Anthony has trained and
                    developed over 300 software developers in his career, many of whom he continues to mentor. As a
                    Release Train Engineer and Certified Professional Coach, he has launched multiple trains in the
                    government sector. He holds several SAFe, Checkmarx, and Pivotal Cloud Foundry (PCF) certifications,
                    and he is also a Certified International Configuration Manager (CICM) and Certified Supply Chain Manager (CSCM).
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default InstructorBio;
