import React, { useEffect, useState } from 'react';
import Menubar from '../components/Menubar';
import Footer from '../components/Footer';
import usePageTracking from '../components/PageTracking';

function dirDetails() {
  // eslint-disable-next-line max-len
  const [pageTitle, setPageTitle] = useState('GSA 8(a) STARS III | Contract Vehicle');
  useEffect(() => {
    document.title = pageTitle; // Set the page title to the current state value
  }, [pageTitle]);

  usePageTracking();

  return (
    <>
      <Menubar />
      {/* Hero */}
      <div className='py-lg-20 pt-14 pb-10 bg-cover ContractVehiclesHeroImage'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-6 col-lg-7 col-md-8 col-12'>
              <div className='text-white'>
                <h1 className='text-white mb-3 display-4 whiteText-responsive'>
                  CONTRACT VEHICLES
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='text-center'>
        <h1 className='pt-5'>GSA 8(a) STARS III</h1>
        <p className='pl-5 pr-5 pb-3'>
        </p>
      </div>
      {/* DIR CARD */}
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 col-md-12'>
            <div className='card bg-light mb-3 contractVehiclesDIRCard'>
              <div className='card-body'>
                <img src="./assets/gsa.svg" alt="GSA" className="img-fluid" />
                <p className='stars3para'>Appddiction Studio LLC (Appddiction)
                  is pleased to be a GSA 8(a) STARS III Government-Wide
                  Acquisition Contract (GWAC) industry partner.
                  It is a competitive multiple award, indefinite-delivery,
                  indefinite-quantity set-aside small business contract.
                  It is designed specifically to provide all
                  federal defense and civilian agencies with an efficient,
                  flexible way to order the full range of worldwide information
                  technology services, solutions, and ancillary support.
                </p>
                <h4 data-testid='title large'>
                  Contact Profile
                </h4>
                <p className='stars3para'
                  data-testid='c overview'>
                Name: GSA 8(a) STARS III<br/>
                Master Contract Number: 47QTCB22D0170<br/>
                Contractor DUNS/UEI Number, CAGE Code (DUNS: 968907076
                / UEI Number: WD4UD1FWVYL3; CAGE Code: 6HR48)<br/>
                Type: GWAC (Multiple Award IDIQ)<br/>
                Task Order Types: Fixed Price, Time & Material,
                and Labor hour<br/>
                Contract Ordering Period: July 02, 2021 -
                July 01, 2029, with one three-year option
                </p>
                <p className='stars3para'>
                Task Order Performance can go 5 years beyond
                  Contract ordering end date.
                </p>
                <p className='stars3para'>
                8(a) STARS III was awarded under NAICS: 541512,
                Computer Systems Design Services. Requirements
                that align with other IT services NAICS codes
                are within the scope of the STARS III GWAC.
                These NAICS codes include, but are not limited to
                541511, 541513, 541519, 518210.
                </p>
                <p className='stars3para'>
                Directed awards allowed for orders up to
                the 8(a) Competitive Threshold.
                </p>
                <p className='stars3para'>
                GSA 8(a) STARS III Website:&nbsp;
                  <a href='http://www.gsa.gov/s3'
                    target={ self }
                  >
                    www.gsa.gov/s3
                  </a>
                </p>
                <div className='stars3para'>Features and Benefits:
                </div>
                <ul>
                  <li className='stars3para'>
                    Program ceiling: $50 billion
                  </li>
                  <li className='stars3para'>
                    Geographic Coverage: Continential United States,
                    some Worldwide
                  </li>
                  <li className='stars3para'>
                    Order placement: Assissted Services Entities
                    or Delegation of Procurement Authority from
                    Procuring Contracting Officer
                  </li>
                  <li className='stars3para'>
                    Supports small business and meets procurement
                    preference goals
                  </li>
                  <li className='stars3para'>
                    Availability and access to proven small business
                    8(a) technology providers
                  </li>
                  <li className='stars3para'>
                    Short procurement lead time
                  </li>
                  <li className='stars3para'>
                    Low user fee (0.75%) built into
                    contractor&apos;s ceiling prices
                  </li>
                </ul>
                <p className='stars3para'>
                  Appddiction studio is prepared to support
                  the entire scope of information technology
                  services and solutions within the GSA 8(a)
                  STARS III contract. We are a highly qualified
                  professional services company supporting valued
                  customers in government and commercial organizations.
                  Every Customer, Employee and Strategic Partner
                  is important to us.
                </p>
                <h3>
                  Using GSA 8(a) STARS III
                </h3>
                <p className='stars3para pb-0 pl-0'>
                (29 U.S.C. § 794d)
                </p>
                <p className='stars3para p-0'>
                  In 1988, Congress amended the Rehabilitation
                  Act of 1973 to require Federal agencies to
                  make their electronic and information technology
                  (EIT) accessible to people with disabilities.
                  The law (<a target={ self }
                    href='https://www.govinfo.gov/content/pkg/USCODE-2011-title29/html/USCODE-2011-title29-chap16-subchapV-sec794d.htm'>
                  29 U.S.C § 794(d)</a>) applies to all
                  Federal agencies when they develop, procure,
                  maintain, or use electronic and information
                  technology. Under section 508, agencies must
                  give disabled employess and members of the
                  public access to information that is comparable
                  to access available to others. The United States
                  Access Board discusses the Section 508 law and
                  its responsibility for developing accessibility
                  standards for EIT to incorporate into regulations
                  that govern Federal procurement practices.
                </p>
                <div className='stars3para font-weight-bold pb-0'>
                  NEW: 508 Standards Refresh
                </div>
                <p className='stars3para'>
                  On January 18, 2017 the Access Board
                  <a href='https://www.access-board.gov/ict/'
                    target={ self }
                  > issued a final rule</a> that updates
                  accessibility requirements for
                  information and communication technology (ICT) in
                  the federal sector covered by Section 508 of the
                  Rehabilitation Act. The rule also refreshes
                  guidelines for telecommunications equipment
                  subject to Section 255 of the Communications Act.
                  The rule jointly updates and reorganizes Section
                  508 standards and Section 255 guidelines in
                  response to market trends and innovations,
                  such as the convergence of technologies.
                  The refresh also harmonizes these requirements
                  with other guidelines and standards both in the
                  U.S. and abroad, including standards issued by
                  the European Commission and with the Web Content
                  Accessibility Guidelines (WCAG), a globally
                  recognized voluntary consensus standard for
                  web content and ICT. In fact, the rule references
                  Level A and Level AA Success Criteria and
                  Conformance Requirements in
                  <a href='https://www.w3.org/WAI/standards-guidelines/wcag/'
                    target={ self }
                  > WCAG 2.0(link is external) </a>
                  and applies them not only to websites,
                  but also to electronic documents and software.
                  For more information, the Access Board has published
                  an <a href='https://www.access-board.gov/ict.html'
                    target={ self }>
                    Overview of the Final Rule
                  </a> Over the next several
                  months, the US Access Board, in partnership with
                  the General Services Administration, will provide
                  guidance on the standards and on how to implement
                  them within the federal government. This guidance
                  will be published on the Section508.gov website
                  when available.
                </p>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-md-12'>
            <div className='card bg-light'>
              <p className='stars3para'><h4>Company Profile</h4>
              Appddiction Studio LLC<br/>
              17211 Jones Maltsberger<br/>
              San Antonio, TX 78247<br/>
              DUNS Number: <span className='font-weight-bold'>
                968907076</span><br/>
              CAGE Code: <span className='font-weight-bold'>6HR48</span><br/>
              </p>
              <p className='stars3para'>
                <h4>GSA 8(a) STARS III Program Manager</h4>
              Timothy Porter<br/>
              17211 Jones Maltsberger Rd<br/>
              San Antonio, TX 78247<br/>
                <a href='mailto:tporter@appddictionstudio.com'>
                tporter@appddictionstudio.com
                </a><br/>
              Office: (210) 704-7828<br/>
              Mobile: (210) 859-9677
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default dirDetails;
