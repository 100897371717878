/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

// const queryParams = new URLSearchParams(window.location.search);
// console.log(queryParams.id);
// const id = queryParams.get('id');

function Preorder() {
  const [thisCourse, setthisCourse] = useState([]);
  const { id } = useParams();
  const getCourse = async () => {
    try {
      const response = await fetch(
        `${window.__ENV__.API_URL}/courses/api/get/${id}`
      );
      const jsonData = await response.json();
      return jsonData;
    } catch (err) {
      console.error(err.message);
    }
  };
  useEffect(async () => {
    const courseList = await getCourse();
    setthisCourse(courseList);
  }, []);
  console.log(thisCourse);

  const [numberOfStudents, setCount] = useState(1);

  function discount() {
    let discountTotal;
    if (thisCourse.earlyBirdDiscountActive === true) {
      discountTotal = numberOfStudents * thisCourse.earlyBirdDiscountAmount;
    } else {
      discountTotal = 0;
    }
    return discountTotal;
  }

  const cartTotal = numberOfStudents * thisCourse.subtotal - discount();

  return (
    <div className="pt-5 pt-lg-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-7 col-12">
            <div className="mb-4">
              <h2 className="h4">Selected Course</h2>
            </div>
            {/* card header */}

            <div className="card table-responsive">
              {/* table */}
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th
                      className="text-uppercase
                    border-bottom border-top-0"
                    ></th>
                    <th className="text-uppercase border-bottom border-top-0">
                      Price
                    </th>
                    <th className="border-bottom border-top-0"></th>
                  </tr>
                </thead>
                <tbody>
                  {/* product */}
                  <tr>
                    <td>
                      <div className="d-flex align-items-center align-middle">
                        <img src={thisCourse.courseBadge} alt="" width="250" />
                        <div className="ms-3">
                          <div className="h4">{thisCourse.courseTitle}</div>
                          <span className="text-uppercase">
                            {thisCourse.startDay}, {thisCourse.durationString}
                            <br />
                            {thisCourse.startTime} - {thisCourse.endTime}
                            <br />
                          </span>
                          {thisCourse.inPerson ? (
                            <img
                              src={thisCourse.locationBadge}
                              className="courseBrands"
                              alt={thisCourse.imgAltTag}
                            />
                          ) : (
                            <img
                              src={thisCourse.locationBadge}
                              className="courseBrands"
                              alt={thisCourse.imgAltTag}
                            />
                          )}
                          <br />
                          {thisCourse.inPerson ? 'In Person' : 'Online Class'}
                        </div>
                      </div>
                    </td>
                    <td className="text-dark align-top h5">
                      ${thisCourse.subtotal}.00
                    </td>
                    <td className="align-middle">
                      <a href="#" className="text-reset font-24">
                        <i className="fe fe-x-circle"> </i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="accordion" id={`accordion${thisCourse.id}`}>
              <div className="card">
                <div className="card-header" id={`heading${thisCourse.id}`}>
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link btn-block text-left"
                      type="button"
                      data-toggle="collapse"
                      data-target={`#collapse${thisCourse.id}`}
                      aria-expanded="true"
                      aria-controls={`collapse${thisCourse.id}`}
                    >
                      COURSE DETAILS{' '}
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </h2>
                </div>
                <div
                  id={`collapse${thisCourse.id}`}
                  className="collapse"
                  aria-labelledby={`heading${thisCourse.id}`}
                  data-parent={`#accordion${thisCourse.id}`}
                >
                  <div
                    className="card-body"
                    dangerouslySetInnerHTML={{
                      __html: thisCourse.stripePrice
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="accordion" id={`accordion2`}>
              <div className="card">
                <div className="card-header">
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link btn-block text-left"
                      type="button"
                    >
                      CAREER PAY{' '}
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </h2>
                </div>
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center">
                    <img
                      src="./assets/learnnow_paylater.png"
                      className="w-50 mb-4"
                    />
                    <Link
                      to="/careerpay-information"
                      className="btn btn-cpay text-uppercase w-75"
                    >
                      CAREERPAY INFORMATION
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Order Summary Section */}
          <div className="col-lg-4 col-md-5 col-12 mt-lg-0 mt-5">
            <div className="mb-4">
              <h2 className="h4">Order summary</h2>
            </div>
            {/* card */}
            <div className="card">
              {/* list */}
              <ul className="list-group list-group-flush">
                <li
                  className="list-group-item d-flex
                  justify-content-between align-items-center p-3"
                >
                  <div className="mb-0 h6">Subtotal</div>
                  <span className="h6">
                    {numberOfStudents} X ${thisCourse.subtotal}.00
                  </span>
                </li>
                {/* list */}
                <li
                  className="list-group-item d-flex
                  justify-content-between align-items-center p-3"
                >
                  <div className="mb-0 h6">Discount</div>
                  <span className="text-danger h6">- ${discount()}.00</span>
                </li>
                {/* list */}
                <li
                  className="list-group-item d-flex
                  justify-content-between align-items-center p-3"
                >
                  <div className="mb-0 h6">Total</div>
                  <span className="text-success h6">
                    ${cartTotal.toLocaleString()}.00
                  </span>
                </li>
                {/* list */}
                <li className="list-group-item p-3">
                  <div className="d-grid">
                    <div className="h6 text-center">
                      Student(s) added in the next step.
                    </div>
                    <div className="d-flex justify-content-center">
                      <Link
                        to={'register' + thisCourse.id}
                        className="shadow btn btn-primary"
                      >
                        CONTINUE TO ADD STUDENT(S)
                      </Link>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Preorder;
