import React from 'react';
import { Link } from 'react-router-dom';

function Menulinks() {
  return (
    <>
      <div className='m-4'>
        <div className='mb-1 mt-4 h5'>CAPABILITIES</div>
        <ul className='list-group'>
          <li className='list-group-item'>
            <Link to='/devsecops'
              className='list-group-item-link'>
                DevSecOps/Software Development
            </Link>
          </li>
          <li className='list-group-item'>
            <Link to='/training'
              className='list-group-item-link'>Agile &amp;
                DevSecOps Training
            </Link>
          </li>
          <li className='list-group-item'>
            <Link to='/modernization'
              className='list-group-item-link'>
                Agile Transformation/
                Modernization
            </Link>
          </li>
          <li className='list-group-item'>
            <Link to='/healthcare'
              className='list-group-item-link'>
                Healthcare Staffing
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}
export default Menulinks;
