/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import Menubar from '../components/Menubar';

const NotFound = () => (
  <>
    <Menubar />
    <div className="">
      <div className="m-5 p-5">
        <div className="col">
          <div className="text-center">
            <div className='display-5'>
              <p>Oops! <br/>It seems like you&apos;ve stumbled upon a lost pixel in the digital universe. 🌌</p>
              <p>Feel free to contact the folks on live chat you have any <br/>urgent inquiries or simply need a friendly chat. 📡
              </p>
              <p>Thank you for your understanding.</p>
              <p>🌌 🚀 ✨</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </>
);

export default NotFound;
