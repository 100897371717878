/* eslint-disable max-len */
import { useEffect, useState } from 'react';

function CPBilling() {
  const [iframeVisible, setIframeVisible] = useState(true);
  const [loginSuccessMessage, setLoginSuccessMessage] = useState('');

  useEffect(() => {
    window.addEventListener('message', function(event) {
      // if (event.origin !== 'http://localhost:3001') {
      // if (event.origin !== 'https://beta.careerpay.com') {
      if (event.origin !== 'https://www.careerpay.com') {
        return;
      }

      const data = event.data;

      if (data.loginSuccess === true) {
        console.log('Login was successful.');
        console.log('Received stripeId:', data.stripeId);
        sessionStorage.setItem('stripeId', data.stripeId);
        sessionStorage.setItem('username', data.username);
        setIframeVisible(false);
        setLoginSuccessMessage('CareerPay login successful');
      } else {
        console.log('Login failed.');
        // Handle login failure
      }
    });
    return () => {
      window.removeEventListener('message');
    };
  }, []);
  return (
    <div className='py-6 py-lg-12'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-12'>
            {/* card */}
            <div className='card mb-4'>
              {/* card header */}
              <div className='card-header bg-white p-4'>
                <h4 className='mb-0'>Login to careerPay</h4>
                {iframeVisible ? (
                  <iframe
                    // src='http://localhost:3001/appddiction-login'
                    // src='https://beta.careerpay.com/appddiction-login'
                    src='https://www.careerpay.com/appddiction-login'
                    width='100%'
                    height='500px'
                    frameBorder='0'
                    allowFullScreen
                  ></iframe>
                ) : (
                  <div className='h2 py-5 text-center cp-text'>{loginSuccessMessage}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CPBilling;
