import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import Menubar from '../components/Menubar';
import DevsecopsContactForm from '../components/DevsecopsContactForm';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import usePageTracking from '../components/PageTracking';
import updateMetaDescription from '../components/DescriptTag';
import updateMetaKeywords from '../components/KeywordTag';

function DevSecOps() {
  // eslint-disable-next-line max-len
  const [pageTitle, setPageTitle] = useState('Agile/Lean Software Development | Applicatiion Development | Application Security | Data Science | Mobile App Development | Low Code/No Code Development');
  useEffect(() => {
    document.title = pageTitle; // Set the page title to the current state value
  }, [pageTitle]);

  // Update meta description dynamically
  useEffect(() => {
    // eslint-disable-next-line max-len
    updateMetaDescription('In the world of agile and lean software development, application security is paramount. Leveraging data science and a data-driven approach, softdev teams ensure robustness and innovation.');
  }, []);

  // Update meta keywords dynamically
  useEffect(() => {
    // eslint-disable-next-line max-len
    updateMetaKeywords('agile, lean, software, development, application, security, data, science, data-driven, softdev, robustness, innovation, mobile, app, low code, no code');
  }, []);

  usePageTracking();
  return (
    <>
      <Menubar />
      {/* Hero */}
      <div className='py-lg-20 pt-14 pb-10 bg-cover softDevHeroImage'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-6 col-lg-7 col-md-8 col-12'>
              <div className='text-white'>
                <h1 className='text-white mb-3 display-4 whiteText-responsive'>
                  AGILE/LEAN<br/>SOFTWARE DEVELOPMENT
                </h1>
                <p className='mb-3 pe-lg-12 pe-0 lead'>
                Our experts provide services in delivering
                working software using advanced technologies &amp;
                platforms.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Navigation Buttons */}
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-6 col-sm-12 pl-0 pr-0'>
            <Link to='/devsecops' className='btn btn-secondary-training pt-3'
              data-testid='btn-classes'>
              <span className='h3'>
                  DEVSECOPS
              </span>
            </Link>
          </div>
          <div className='col-md-6 col-sm-12 pl-0 pr-0'>
            <Link to='/softdev' className='btn btn-primary-training pt-3'
              data-testid='btn-public'>
              <span className='h3'>
                  SOFTWARE DEVELOPMENT
              </span>
            </Link>
          </div>
        </div>
      </div>
      {/* Page Content - Cards */}
      <div className='p-5'>
        <div className='container-fluid'>
          <div className='row justify-content-center'>
            {/* Cards start here */}
            <Card id='devSecOpsCard'>
              <Card.Img src='./assets/AgileLeanSoftdev.png'
                className='cardImages' alt='agilelean'/>
              <Card.Body className='d-flex flex-column' >
                <Card.Title data-testid='agilelean'>AGILE/LEAN
                  <br/> SOFTWARE DEVELOPMENT
                </Card.Title>
                <Card.Text>Introducing modern software development
                  practices to traditional environments.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card id='devSecOpsCard'>
              <Card.Img src='./assets/MultiStack.png' className='cardImages'
                alt='multistack'/>
              <Card.Body className='d-flex flex-column' >
                <Card.Title data-testid='multistack'>
                MULTI-STACK <br/>EXPERTISE
                </Card.Title>
                <Card.Text>We provide expertise in various tech stacks using
                  .Net, Java, PHP, Ruby, NodeJs and more.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card id='devSecOpsCard'>
              <Card.Img src='./assets/AppSecurity.png' className='cardImages'
                alt='appsecurity'/>
              <Card.Body className='d-flex flex-column'>
                <Card.Title data-testid='appsecurity'>
                APPLICATION<br/>SECURITY
                </Card.Title>
                <Card.Text>Address rising security threats with our expertise,
                  we can prevent, find, and fix security weaknesses at the
                  application level in development, software, and hardware
                  processes.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card id='devSecOpsCard'>
              <Card.Img src='./assets/artificialintelligence.png'
                className='cardImages'alt='artificialintelligence'/>
              <Card.Body className='d-flex flex-column' >
                <Card.Title data-testid='artificialintelligence'>
                DATA SCIENCE &amp; <br />ARTIFICIAL INTELLIGENCE
                </Card.Title>
                <Card.Text>Convert your data into data-driven models and
                  actionable intelligence by using our data science tools.
                  Discover creative technology solutions which enable you
                  to increase overall project impact, better project
                  management, and effective decision making.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card id='devSecOpsCard'>
              <Card.Img src='./assets/MobileApp.png' className='cardImages'
                alt='mobileapp'/>
              <Card.Body className='d-flex flex-column' >
                <Card.Title data-testid='mobileapp'>
                MOBILE APP DEVELOPMENT
                </Card.Title>
                <Card.Text >iOS and Android mobile app experts,
                  Appddiction Studio has developed 100&apos;s
                  of Mobile Applications.
                  We can create what you&apos;re looking for.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card id='devSecOpsCard'>
              <Card.Img src='./assets/LowCodeNoCode.png'
                className='cardImages' alt='lowcodenocode'/>
              <Card.Body className='d-flex flex-column' >
                <Card.Title data-testid='lowcodenocode'>
                LOW CODE/ NO CODE
                </Card.Title>
                <Card.Text >Cost effective, quickly implemented,
                  uses rules for simple decision-making and allows
                  users to build software applications without the
                  need to write code.
                </Card.Text>
              </Card.Body>
            </Card>
            {/* Cards end here */}
          </div>
        </div>
      </div>
      {/* DevSecOpsContactForm */}
      <DevsecopsContactForm />
      {/* Footer */}
      <Footer />
    </>
  );
}
export default DevSecOps;
